import React, { FC, useState, useCallback } from 'react';
import sanitizeHtml from 'sanitize-html';
import { Theme, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Typography from 'components/Typography';
import { formatDate } from '../utils';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import useTranslation from 'hooks/useTranslation';

const useToggle = (initial = false): [boolean, (state?: unknown) => void] => {
  const [on, setOn] = useState(initial);

  const toggle = useCallback(
    (state: unknown) => {
      if (typeof state === 'boolean') {
        setOn(state);
      } else {
        setOn(prevState => !prevState);
      }
    },
    [setOn],
  );

  return [on, toggle];
};

const useStyles = makeStyles()((theme: Theme) => ({
  messageWrapper: {
    width: '70%',

    '&:not(:first-of-type)': {
      marginTop: theme.spacing(2),
    },
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  messageAuthor: {
    fontWeight: 'bold',
  },
  messageText: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  inner: {
    marginLeft: 'auto',
  },
  outer: {
    marginRight: 'auto',
  },
}));

export interface MessageProps {
  author?: string;
  message: string;
  inner: boolean;
  date: string;
  remark?: boolean;
  classes?: {
    message?: string;
    messageWrapper?: string;
    messageAuthor?: string;
    messageText?: string;
    messageDate?: string;
  };
}

const Message: FC<MessageProps> = ({
  author,
  message,
  inner,
  date,
  remark,
  classes,
}) => {
  const { classes: innerClasses, cx } = useStyles();
  const [showDate, toggleShowDate] = useToggle(false);
  const { t } = useTranslation();

  return (
    <Box
      className={cx(
        { [innerClasses.inner]: inner, [innerClasses.outer]: !inner },
        innerClasses.messageWrapper,
        classes?.messageWrapper,
      )}
    >
      {author && (
        <Typography
          className={cx(innerClasses.messageAuthor, classes?.messageAuthor)}
          variant="subtitle2"
          noWrap
          tooltip
          tooltipProps={{
            disableCopy: true,
          }}
        >
          {author}
        </Typography>
      )}
      <Box
        className={classes?.message}
        onClick={toggleShowDate}
        sx={theme => ({
          padding: 1,
          borderRadius: '8px',
          ...(author
            ? {
                border: `1px solid ${theme.palette.divider}`,
              }
            : {
                backgroundColor: theme.palette.primary.main,
              }),
          position: 'relative',
        })}
      >
        <Typography
          variant="body1"
          className={cx(innerClasses.messageText, classes?.messageText)}
          sx={{
            ...(!author && {
              color: 'white',
            }),
          }}
        >
          {sanitizeHtml(message, { allowedTags: [], allowedAttributes: {} })}
        </Typography>
        {remark && (
          <Tooltip
            title={t('Remark created without notifying the WeDeclare team')}
          >
            <NotificationsOffIcon
              sx={{
                color: '#6f7072',
                position: 'absolute',
                right: 4,
                bottom: 4,
                height: 18,
                width: 18,
              }}
            />
          </Tooltip>
        )}
      </Box>
      {showDate && (
        <Typography
          variant="caption"
          className={cx(classes?.messageDate)}
          sx={theme => ({
            padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
            color: theme.palette.text.secondary,
          })}
        >
          {formatDate(date)}
        </Typography>
      )}
    </Box>
  );
};

export default Message;
