import { setCssVariable } from 'helpers';
import React, {
  useContext,
  useReducer,
  createContext,
  ReactNode,
  useMemo,
  useLayoutEffect,
} from 'react';

enum ReducerActions {
  setSidebarOpen,
}

type State = {
  sidebarOpen: boolean;
};

type Action = {
  type: ReducerActions.setSidebarOpen;
  payload: {
    sidebarOpen: State['sidebarOpen'];
  };
};

type Actions = {
  setSidebarOpen: (sidebarOpen: State['sidebarOpen']) => void;
};

const LayoutContext = createContext<
  | {
      layoutState: State;
      layoutActions: Actions;
    }
  | undefined
>(undefined);

export const LayoutProvider = ({
  children,
  initialState,
}: {
  children: ReactNode;
  initialState?: Partial<State>;
}) => {
  const [state, dispatch] = useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case ReducerActions.setSidebarOpen: {
          return {
            ...state,
            ...action.payload,
          };
        }
        default: {
          return state;
        }
      }
    },
    {
      sidebarOpen: false,
      ...initialState,
    },
  );

  useLayoutEffect(() => {
    setCssVariable(
      '--sidebar-width',
      `${initialState?.sidebarOpen ? 248 : 76}px`,
    );
  }, []);

  const actions = useMemo(
    () =>
      ({
        setSidebarOpen: sidebarOpen => {
          setCssVariable('--sidebar-width', `${sidebarOpen ? 248 : 76}px`);
          dispatch({
            type: ReducerActions.setSidebarOpen,
            payload: {
              sidebarOpen,
            },
          });
        },
      } as Actions),
    [],
  );

  return (
    <LayoutContext.Provider
      value={{
        layoutState: state,
        layoutActions: actions,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }
  return context;
};
