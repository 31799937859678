import React, { useEffect } from 'react';
import useTranslation from 'hooks/useTranslation';
import { FilterIcon, FilterOffIcon } from 'components/Icons';
import IconButton, { IconButtonProps } from 'components/IconButton';

export default ({
  open,
  onClick,
  tooltipProps,
  ...rest
}: IconButtonProps & { open?: boolean }) => {
  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = React.useState(
    tooltipProps?.open ?? false,
  );
  useEffect(
    () => setTooltipOpen(tooltipProps?.open ?? false),
    [tooltipProps?.open],
  );

  const handleTooltipOpen = (e: React.SyntheticEvent) => {
    setTooltipOpen(true);
    tooltipProps?.onOpen?.(e);
  };

  const handleTooltipClose = (e: React.SyntheticEvent | Event) => {
    setTooltipOpen(false);
    tooltipProps?.onClose?.(e);
  };

  return (
    <IconButton
      tooltip={t(open ? 'Hide Filters' : 'Show Filters')}
      onClick={e => {
        handleTooltipClose(e);
        onClick?.(e);
      }}
      tooltipProps={{
        ...tooltipProps,
        open: tooltipOpen,
        onOpen: handleTooltipOpen,
        onClose: handleTooltipClose,
      }}
      padding
      {...rest}
    >
      {open ? <FilterOffIcon /> : <FilterIcon />}
    </IconButton>
  );
};
