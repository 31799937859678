import dayjs from 'dayjs';

export const formatDate = (date: string) => {
  const currentDate = dayjs();
  const momentDate = dayjs(date);

  const dayPass = currentDate.diff(momentDate, 'days');

  if (dayPass === 0 || dayPass === 1) {
    return momentDate.fromNow();
  }

  if (
    momentDate.isBefore(currentDate.endOf('week')) &&
    momentDate.isAfter(currentDate.startOf('week'))
  ) {
    return momentDate.format('dddd hh:mm a');
  }

  return momentDate.format('DD MMMM hh:mm a');
};
