import React, { FC } from 'react';
import { DialogActions as MuiDialogActions, Theme } from '@mui/material';
import CancelButton, {
  CancelButtonProps,
} from 'components/Button/CancelButton';
import ConfirmButton, {
  ConfirmButtonProps,
} from 'components/Button/ConfirmButton';

export interface DialogActionsProps {
  onClose: () => void;
  onConfirm: () => void;
  CancelButtonProps?: Partial<CancelButtonProps>;
  ConfirmButtonProps?: Partial<ConfirmButtonProps>;
}

const DialogActions: FC<DialogActionsProps> = ({
  onClose,
  onConfirm,
  CancelButtonProps,
  ConfirmButtonProps,
}) => {
  return (
    <MuiDialogActions
      sx={theme => ({
        p: 1.5,
        borderTop: 1,
        borderColor: theme.palette.divider,
      })}
    >
      <CancelButton onClick={onClose} {...CancelButtonProps} />
      <ConfirmButton onClick={onConfirm} {...ConfirmButtonProps} />
    </MuiDialogActions>
  );
};

export default DialogActions;
