import React, { FC, useEffect, useState } from 'react';
import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Tab,
  Tabs,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import IconButton from 'components/IconButton';
import { combineSX } from 'helpers';

export interface DialogTitleProps extends Omit<MuiDialogTitleProps, 'classes'> {
  onClose: () => void;
  isCloseDisabled?: boolean;
  className?: string;
  classes?: {
    root?: string;
    closeButton?: string;
  };
  tabs?: string[];
  selectedTab?: number;
  onChangeTab?: (newTab: number) => void;
  getTabDisabled?: (tabIndex: number) => boolean;
}

const DialogTitle: FC<DialogTitleProps> = ({
  children,
  onClose,
  isCloseDisabled,
  className,
  classes,
  tabs,
  selectedTab,
  onChangeTab,
  getTabDisabled,
  component = 'div',
  sx,
  ...rest
}) => {
  return (
    <MuiDialogTitle
      className={clsx(className, classes?.root)}
      component={component}
      sx={combineSX(
        theme => ({
          padding: 2,
          borderBottom: 1,
          borderColor: theme.palette.divider,
          ...(!!tabs?.length && {
            paddingBottom: 0,
          }),
        }),
        sx,
      )}
      {...rest}
    >
      <IconButton
        className={classes?.closeButton}
        onClick={onClose}
        disabled={isCloseDisabled}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
        }}
      >
        <CloseIcon sx={{ fontSize: 18 }} />
      </IconButton>
      {children}
      {tabs?.length && (
        <Tabs
          value={selectedTab}
          onChange={(e, newSelectedTab) => {
            onChangeTab?.(newSelectedTab);
          }}
          indicatorColor="primary"
          sx={{
            mt: 1.5,
            minHeight: 26,
            overflow: 'visible',
            '& .MuiTabs-scroller': {
              overflow: 'visible!important',
            },
            '& .MuiTabs-indicator': {
              bottom: -1,
            },
          }}
        >
          {tabs.map(tab => (
            <Tab
              key={tab}
              label={tab}
              disabled={getTabDisabled?.(tabs.indexOf(tab))}
              sx={theme => ({
                minHeight: 26,
              })}
            />
          ))}
        </Tabs>
      )}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
