import React, { FC, ElementType, ReactNode } from 'react';
import { Box, BoxProps, SvgIconProps } from '@mui/material';
import Typography from '../Typography';
import { combineSX } from 'helpers';

export interface TitleProps extends BoxProps {
  subTitle?: ReactNode;
  classes?: {
    root?: string;
    title?: string;
    subTitle?: string;
  };
  className?: string;
  Icon?: ElementType<SvgIconProps>;
}

const Title: FC<TitleProps> = ({ children, subTitle, Icon, sx, ...rest }) => {
  return (
    <Box
      className="LuiTitle-root"
      sx={combineSX(
        {
          display: 'flex',
          alignItems: 'center',
        },
        sx,
      )}
      {...rest}
    >
      {Icon && (
        <Box
          className="LuiTitle-iconContainer"
          sx={{
            mr: 2,
            minHeight: 36,
            minWidth: 36,
            maxHeight: 36,
            maxWidth: 36,
            borderRadius: 50,
            backgroundColor: '#dfdbff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon
            className="LuiTitle-icon"
            sx={theme => ({
              color: theme.palette.primary.main,
            })}
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column" justifyContent="center">
        {typeof children === 'string' ? (
          <Typography className="LuiTitle-title" variant="h4">
            {children}
          </Typography>
        ) : (
          children
        )}
        {typeof subTitle === 'string' ? (
          <Typography
            className="LuiTitle-subTitle"
            color="textSecondary"
            sx={{
              mt: 0.5,
            }}
          >
            {subTitle}
          </Typography>
        ) : (
          subTitle
        )}
      </Box>
    </Box>
  );
};

export default Title;
