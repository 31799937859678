import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Fab,
  SxProps,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { combineSX } from 'helpers';
import SwipeableDrawer from 'components/Drawer/SwipeableDrawer';
import { useLayoutContext } from '../LayoutContext';
import { ArrowLeftSmallIcon, ArrowRightSmallIcon } from 'components/Icons';

export interface SideBarProps {
  topContent?: React.ReactNode;
  navigationContent?: React.ReactNode;
  slotProps?: {
    topContent?: BoxProps;
    navigationContent?: BoxProps;
  };
  sx?: SxProps<Theme>;
}

const SideBar: FC<SideBarProps> = ({
  topContent,
  navigationContent,
  slotProps,
  sx,
  ...rest
}) => {
  const isUpLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('laptop'),
    {
      noSsr: true,
    },
  );
  const {
    layoutState: { sidebarOpen: open },
    layoutActions: { setSidebarOpen },
  } = useLayoutContext();
  const handleToggleSidebar = () => setSidebarOpen(!open);

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="left"
        onClose={handleToggleSidebar}
        onOpen={handleToggleSidebar}
        open={open}
        {...(isUpLaptop && {
          variant: 'permanent',
        })}
        sx={combineSX(
          theme => ({
            whiteSpace: 'nowrap',
            '& .MuiDrawer-paper': {
              overflow: 'hidden',
              boxSizing: 'border-box',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              gap: '16px',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: open
                  ? theme.transitions.duration.enteringScreen
                  : theme.transitions.duration.leavingScreen,
              }),
              [theme.breakpoints.up('laptop')]: {
                width: 'var(--sidebar-width)',
              },
            },
          }),
          sx,
        )}
        {...rest}
      >
        <Box
          {...slotProps?.topContent}
          sx={combineSX(
            {
              minHeight: 52,
              maxHeight: 52,
              padding: '16px',
              paddingLeft: '24px',
            },
            slotProps?.topContent?.sx,
          )}
        >
          {topContent}
        </Box>
        <Box
          {...slotProps?.navigationContent}
          sx={combineSX(
            {
              flexGrow: 1,
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
            },
            slotProps?.navigationContent?.sx,
          )}
        >
          {navigationContent}
        </Box>
      </SwipeableDrawer>
      {isUpLaptop && (
        <Fab
          onClick={handleToggleSidebar}
          color="primary"
          sx={theme => ({
            position: 'fixed',
            left: 'calc(var(--sidebar-width) - 24px)',
            transition: theme.transitions.create('left', {
              easing: theme.transitions.easing.sharp,
              duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
            }),
            bottom: 40,
            height: 44,
            width: 44,
            zIndex: theme.zIndex.drawer + 1,
          })}
        >
          {open ? <ArrowLeftSmallIcon /> : <ArrowRightSmallIcon />}
        </Fab>
      )}
    </>
  );
};

export default SideBar;
