import React, { FC, forwardRef } from 'react';
import { Slide as PRCSlide, SlideProps, WithStore } from 'pure-react-carousel';

interface StateData {
  currentSlide: number;
  slideSize: number;
  totalSlides: number;
  step: number;
  visibleSlides: number;
}

interface ISlideProps extends SlideProps {
  withoutVirtualization?: boolean;
}

const Slide: FC<ISlideProps & StateData> = forwardRef((props, ref: any) => {
  const {
    currentSlide,
    slideSize,
    totalSlides,
    step,
    index,
    style = {},
    withoutVirtualization = false,
    visibleSlides,
    ...rest
  } = props;

  const indexFrom = currentSlide - step;
  const indexTo = currentSlide + step + visibleSlides - 1;
  const isSlideVisible = indexFrom <= index && index <= indexTo;

  if (index !== 0 && !isSlideVisible && !withoutVirtualization) {
    return null;
  }

  let updatedStyle = style;
  if (index !== 0 && !withoutVirtualization) {
    updatedStyle = {
      ...updatedStyle,
      position: 'absolute',
      left: `${index * slideSize}%`,
    };
  }

  return (
    <PRCSlide
      {...rest}
      //@ts-ignore
      ref={ref}
      index={index}
      style={updatedStyle}
    />
  );
});

// @ts-ignore
export default WithStore<ISlideProps>(Slide, state => ({
  currentSlide: state.currentSlide,
  slideSize: state.slideSize,
  totalSlides: state.totalSlides,
  step: state.step,
  visibleSlides: state.visibleSlides,
}));
