import React, { FC } from 'react';
import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material';
import { combineSX } from '../..';

interface MenuItemProps extends MuiMenuItemProps {}

const MenuItem: FC<MenuItemProps> = props => {
  return (
    <MuiMenuItem
      {...props}
      sx={combineSX(
        {
          '&:not(:last-child)': {
            borderBottom: '1px solid #D9D5E9',
          },
        },
        props.sx,
      )}
    />
  );
};

export default MenuItem;
