import React, { forwardRef } from 'react';
import {
  Box,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  LinearProgress,
  Tooltip,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { combineSX } from 'helpers';
import Typography from '../Typography';

export interface ButtonProps
  extends Omit<MuiButtonProps, 'css' | 'variant' | 'color'> {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'destructive';
  loading?: boolean;
  minimizeForMobiles?: boolean;
  unsavedChanges?: boolean;
  tooltip?: TooltipProps['title'] | null;
  tooltipProps?: Partial<Omit<TooltipProps, 'title'>>;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      loading,
      startIcon,
      endIcon,
      minimizeForMobiles,
      unsavedChanges,
      variant = 'primary',
      tooltip,
      tooltipProps,
      onClick,
      sx,
      ...rest
    },
    ref,
  ) => {
    const disabled = rest.disabled || loading;
    const theme = useTheme();
    const isUpTablet = useMediaQuery(theme.breakpoints.up('tablet'), {
      noSsr: true,
    });
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const button = (
      <MuiButton
        variant={
          (
            {
              primary: 'contained',
              secondary: 'outlined',
              tertiary: 'text',
              destructive: 'outlined',
            } as const
          )[variant]
        }
        color={
          (
            {
              primary: 'primary',
              secondary: 'primary',
              tertiary: 'primary',
              destructive: 'error',
            } as const
          )[variant]
        }
        sx={combineSX(
          theme => ({
            position: 'relative',
            overflow: 'hidden',
            minWidth: 'auto',
            ...((variant === 'secondary' || variant === 'destructive') && {
              borderColor: theme.palette.divider,
            }),
            ...(unsavedChanges && {
              '&:after': {
                content: '"*"',
                position: 'absolute',
                top: 6,
                right: 3,
                fontSize: 25,
              },
            }),
            '&.Mui-disabled': {
              pointerEvents: 'all',
            },
          }),
          sx,
        )}
        ref={ref}
        startIcon={minimizeForMobiles ? isUpTablet && startIcon : startIcon}
        endIcon={minimizeForMobiles ? isUpTablet && endIcon : endIcon}
        {...rest}
        disabled={disabled}
        {...(!disabled && { onClick })}
      >
        <Box
          className="LuiButton-content"
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
          })}
        >
          {minimizeForMobiles && (startIcon || endIcon) && !isUpTablet ? (
            <>
              {startIcon}
              {endIcon}
            </>
          ) : typeof children === 'string' ? (
            <Typography
              variant="button"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {children}
            </Typography>
          ) : (
            children
          )}
        </Box>
        {loading && (
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          />
        )}
      </MuiButton>
    );

    return tooltip ? (
      <Tooltip
        title={tooltip}
        disableInteractive
        open={tooltipOpen}
        onOpen={() => setTooltipOpen(true)}
        onClose={() => setTooltipOpen(false)}
        {...tooltipProps}
      >
        {button}
      </Tooltip>
    ) : (
      <>{button}</>
    );
  },
);

export default Button;
