import i18next from 'i18next';
import en from 'locales/en-US.json';
import de from 'locales/de-CH.json';

const i18nextInstance = i18next.createInstance({
  lng: 'de',
  ns: ['common'],
  defaultNS: 'common',
  resources: {
    en,
    de,
  },
  keySeparator: false,
  nsSeparator: ':',
  interpolation: {
    escapeValue: false,
  },
});
i18nextInstance.init();

export default i18nextInstance;
