import React, { FC, memo, useCallback } from 'react';
import Select, { SelectProps } from '../Select';
import { useField } from 'formik';
import useTranslation from 'hooks/useTranslation';

interface FormSelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
  name: string;
  onChange?(value: any): void;
}

const MemoSelect: FC<SelectProps> = memo(props => <Select {...props} />);

const FormSelect: FC<FormSelectProps> = ({ name, onChange, ...rest }) => {
  const [{ value, onBlur }, { error, touched }, { setValue, setTouched }] =
    useField(name);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (newValue: any) => {
      if (!touched) {
        setTouched(true);
      }
      onChange?.(newValue);
      setValue(newValue);
    },
    [touched, setTouched, onChange, setValue],
  );

  return (
    <MemoSelect
      {...rest}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={!!(error && touched)}
      helperText={
        error &&
        touched &&
        (typeof error === 'string'
          ? error
          : //@ts-ignore
            t(`yup:${error.key}`, error.values))
      }
    />
  );
};

export default FormSelect;
