import React, { FC } from 'react';
import { Avatar, Box, BoxProps } from '@mui/material';
import { User } from '../../types';
import Typography from 'components/Typography';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';

interface UserInfoProps extends BoxProps {
  user: User;
}

const UserInfo: FC<UserInfoProps> = ({ user }) => {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
      })}
    >
      <Avatar sx={{ width: 44, height: 44 }}>
        <FaceOutlinedIcon color="primary" />
      </Avatar>
      <Box
        sx={{
          ml: '12px',
        }}
      >
        <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
          {user.name} {user.surname}
        </Typography>
        <Typography variant="body2">{user.email}</Typography>
      </Box>
    </Box>
  );
};

export default UserInfo;
