import React, { FC } from 'react';
import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
} from '@mui/material';
import { combineSX } from '../..';

const ListItemText: FC<MuiListItemTextProps> = ({
  primaryTypographyProps,
  ...rest
}) => {
  return (
    <MuiListItemText
      {...rest}
      primaryTypographyProps={{
        color: 'text.secondary',
        ...primaryTypographyProps,
        sx: combineSX(
          theme => ({
            ...theme.typography.body1,
          }),
          primaryTypographyProps?.sx,
        ),
      }}
    />
  );
};

export default ListItemText;
