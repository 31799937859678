import React, { FC, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Typography from 'components/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props extends Omit<BoxProps, 'content'> {
  title: string;
  content: ReactNode;
  active: boolean;
}

export default ({ title, content, active }: Props) => {
  const [expanded, setExpanded] = React.useState(active);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(prev => !prev)}
      disableGutters
      square
      sx={theme => ({
        border: 'unset',
        boxShadow: 'unset',
        borderBottom: 1,
        borderColor: theme.palette.divider,
        '&:before': {
          display: 'none',
        },
      })}
    >
      <AccordionSummary
        sx={theme => ({
          minHeight: 'unset',
          px: 2,
          py: 1.5,
          '&.Mui-expanded': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            m: 0,
          },
        })}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{title}</Typography>
        {active && (
          <Box
            sx={{
              borderRadius: '50%',
              width: 8,
              height: 8,
              backgroundColor: 'primary.main',
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={theme => ({
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: 2,
          backgroundColor: theme.palette.surface.secondary,
        })}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  );
};
