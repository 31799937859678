import React, { FC } from 'react';

import MuiToggleButtonGroup, {
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import ToggleButton, { ToggleButtonProps } from './ToggleButton';

interface ToggleButtonGroupPropsPart {
  options?: ToggleButtonProps[];
}

export type ToggleButtonGroupProps = ToggleButtonGroupPropsPart &
  Omit<MuiToggleButtonGroupProps, keyof ToggleButtonGroupPropsPart>;

const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({
  color = 'primary',
  options,
  children,
  ...rest
}) => {
  return (
    <MuiToggleButtonGroup {...rest} color={color}>
      {options?.map((option, i) => <ToggleButton key={i} {...option} />) ??
        children}
    </MuiToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
