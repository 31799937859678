import React, { FC, useCallback, useMemo, CSSProperties } from 'react';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { Paper, Box, MenuItem, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Select from 'components/Select/Select';
import Typography from 'components/Typography/Typography';
import IconButton from 'components/IconButton';

export interface TablePaginationProps {
  page: number;
  totalItems?: number;
  itemsPerPage: number;
  itemsPerPageOptions?: number[];
  onChangePage: (newPage: number) => void;
  onChangeItemsPerPage: (newItemsPerPage: number) => void;
  isLastPage?: boolean;
  className?: string;
  style?: CSSProperties;
}

export const TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS = [5, 10, 50, 100, 200];
export const TABLE_PAGINATION_DEFAULT_ITEMS_PER_PAGE = 10;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  paper: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    height: 40,
    borderRadius: 4,
    border: '1px solid #C8C8D4',
    boxSizing: 'border-box',
    gap: 8,
  },
  arrowButtons: {},
  arrowButtonIcon: {
    width: 18,
    height: 18,
  },
  select: {
    '& fieldset': {
      borderWidth: 0,
    },
  },
}));

const TablePagination: FC<TablePaginationProps> = ({
  page,
  totalItems,
  itemsPerPage,
  itemsPerPageOptions = TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  onChangePage,
  onChangeItemsPerPage,
  isLastPage,
  className,
  style,
}) => {
  const { classes, cx } = useStyles();

  const totalPages = useMemo(
    () => totalItems && Math.ceil(totalItems / itemsPerPage),
    [totalItems, itemsPerPage],
  );

  if (totalPages && (page < 1 || page > totalPages)) {
    throw new Error('page out of range');
  }
  if (!itemsPerPageOptions.includes(itemsPerPage)) {
    throw new Error('itemsPerPage out of range');
  }

  const handleChangeItemsPerPage = (value: any) => {
    if (onChangeItemsPerPage) {
      onChangeItemsPerPage(value);
    }
  };

  const handleChangePage = useCallback(
    (newPage: number) => () => {
      onChangePage(newPage);
    },
    [onChangePage],
  );

  return (
    <Box className={cx(className, classes.root)} display="flex" style={style}>
      <Paper
        className={classes.paper}
        style={{ marginRight: '4px', paddingLeft: 0, paddingRight: 0 }}
        elevation={0}
      >
        <Select
          variant="outlined"
          onChange={handleChangeItemsPerPage}
          value={itemsPerPage}
          className={classes.select}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          {itemsPerPageOptions.map(itemsPerPageOption => (
            <MenuItem value={itemsPerPageOption} key={itemsPerPageOption}>
              {itemsPerPageOption}
            </MenuItem>
          ))}
        </Select>
      </Paper>
      <Paper className={classes.paper} elevation={0}>
        <IconButton
          className={classes.arrowButtons}
          disabled={page <= 1}
          onClick={handleChangePage(1)}
        >
          <FirstPage className={classes.arrowButtonIcon} />
        </IconButton>
        <IconButton
          className={classes.arrowButtons}
          disabled={page <= 1}
          onClick={handleChangePage(page - 1)}
        >
          <NavigateBefore className={classes.arrowButtonIcon} />
        </IconButton>
        <Typography>{page + (totalPages ? ` / ${totalPages}` : '')}</Typography>
        <IconButton
          className={classes.arrowButtons}
          disabled={(!!totalPages && page >= totalPages) || isLastPage}
          onClick={handleChangePage(page + 1)}
        >
          <NavigateNext className={classes.arrowButtonIcon} />
        </IconButton>
        {totalPages && (
          <IconButton
            className={classes.arrowButtons}
            disabled={page >= totalPages}
            onClick={handleChangePage(totalPages)}
          >
            <LastPage className={classes.arrowButtonIcon} />
          </IconButton>
        )}
      </Paper>
    </Box>
  );
};

export default TablePagination;
