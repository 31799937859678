import React, { FC, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import Typography from 'components/Typography';
import Button from 'components/Button';
import useTranslation from 'hooks/useTranslation';
import { combineSX } from '../..';
import FilterSidebarGroup from './FilterSidebarGroup';

export interface FiltersSidebarProps extends BoxProps {
  groups: { title: string; content: ReactNode; active: boolean }[];
  onClear: () => void;
}

const FiltersSidebar: FC<FiltersSidebarProps> = ({ groups, onClear, sx }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={combineSX(
        theme => ({
          display: 'flex',
          flexDirection: 'column',
          width: 240,
          height: '-webkit-fill-available',
          maxHeight: 'calc(100vh - var(--appbar-height))',
          overflow: 'auto',
          borderTopRightRadius: '12px',
          borderBottomRightRadius: '12px',
          borderRight: `1px solid ${theme.palette.divider}`,
          boxShadow: `
            3px 0px 6px 0px #D1D1D11A,
            11px 0px 11px 0px #D1D1D117,
            25px 0px 15px 0px #D1D1D10D,
            44px 0px 18px 0px #D1D1D103,
            68px 0px 19px 0px #D1D1D100
          `,
          position: 'fixed',
          top: 'var(--appbar-height)',
        }),
        sx,
      )}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: theme.palette.divider,
        })}
      >
        <Typography variant="h4">{t('Filters')}</Typography>
        <Button
          variant="secondary"
          onClick={onClear}
          disabled={groups.every(group => !group.active)}
        >
          {t('Clear')}
        </Button>
      </Box>
      <Box>
        {groups.map(({ title, content, active }) => (
          <FilterSidebarGroup
            key={title}
            title={title}
            content={content}
            active={active}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FiltersSidebar;
