import React from 'react';
import { Theme, IconButton } from '@mui/material';
import TableBodyCell, { TableBodyCellProps } from './TableBodyCell';
import { makeStyles } from 'tss-react/mui';

interface TableBodyExpandCellProps<T> extends TableBodyCellProps<T> {
  expanded: boolean;
  onExpand: () => void;
}

const useStyles = makeStyles()(theme => ({
  controllerCell: {
    width: '1%',
  },
}));

function TableBodyExpandCell<T>({
  row,
  expanded,
  onExpand,
}: TableBodyExpandCellProps<T>) {
  const { classes } = useStyles();

  return (
    <TableBodyCell
      className={classes.controllerCell}
      disabled={row.disabled}
      row={row}
      cell={() => (
        <IconButton
          size="small"
          onClick={onExpand}
          disabled={row.expandable === false}
        >
          {(() => {
            if (row.expandable === false || row.disabled)
              return (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.6"
                    d="M2 5C1.44772 5 1 5.44772 1 6V7C1 7.55228 1.44772 8 2 8H18.1879C18.6364 8 19 7.63639 19 7.18786C19 6.73933 18.6364 6.37572 18.1879 6.37572H8.19997C7.89036 6.37572 7.59822 6.23231 7.40884 5.98738L6.9457 5.38834C6.75633 5.14341 6.46418 5 6.15458 5H2Z"
                    fill="#737A96"
                  />
                  <path
                    d="M19 9C19 8.44772 18.5523 8 18 8H2C1.44772 8 1 8.44771 1 9V18C1 18.5523 1.44772 19 2 19H18C18.5523 19 19 18.5523 19 18V9Z"
                    fill="#C8C8D4"
                    stroke="#737A96"
                  />
                  <path
                    d="M1 8V18C1 18.5523 1.44772 19 2 19H18C18.5523 19 19 18.5523 19 18V8M1 8V6C1 5.44772 1.44772 5 2 5H6.15458C6.46418 5 6.75633 5.14341 6.9457 5.38834L7.40884 5.98738C7.59822 6.23231 7.89036 6.37572 8.19997 6.37572H18C18.5523 6.37572 19 6.82344 19 7.37572V8M1 8H19"
                    stroke="#737A96"
                  />
                </svg>
              );
            if (expanded)
              return (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 22.75 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.1176 8.70588V7.2659V6.37572H7.75294L6.68235 5H1V7.2659V19L4.70588 8.70588H19.1176Z"
                    fill="#604DFF"
                  />
                  <path
                    d="M1.82353 19H18.2941C18.7489 19 19.1176 18.6313 19.1176 18.1765V7.2659V7.19925C19.1176 6.74443 18.7489 6.37572 18.2941 6.37572H8.15558C7.90152 6.37572 7.66169 6.25846 7.50566 6.05796L6.92963 5.31776C6.77361 5.11726 6.53377 5 6.27971 5H1.82353C1.36871 5 1 5.36871 1 5.82353V7.2659V18.1765C1 18.6313 1.36871 19 1.82353 19Z"
                    stroke="#604DFF"
                  />
                  <path
                    d="M1.39689 17.8975C1.20369 18.4341 1.60136 18.9999 2.17174 18.9999H18.5803C18.9068 18.9999 19.2025 18.807 19.3341 18.5081L23.1388 9.86101C23.3783 9.31673 22.9796 8.70581 22.385 8.70581H19.1176H5.28468C4.93741 8.70581 4.62746 8.92365 4.50983 9.25039L1.39689 17.8975Z"
                    fill="#7B6CFA"
                  />
                  <path
                    d="M2.17174 18.9999H18.5803C18.9068 18.9999 19.2025 18.807 19.3341 18.5081L23.1388 9.86101C23.3783 9.31673 22.9796 8.70581 22.385 8.70581H19.1176H5.28468C4.93741 8.70581 4.62746 8.92365 4.50983 9.25039L1.39689 17.8975C1.20369 18.4341 1.60136 18.9999 2.17174 18.9999Z"
                    stroke="#604DFF"
                  />
                </svg>
              );
            return (
              <svg
                width="24"
                height="24"
                viewBox="0 0 20 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.6"
                  d="M2 5C1.44772 5 1 5.44772 1 6V7C1 7.55228 1.44772 8 2 8H18.1879C18.6364 8 19 7.63639 19 7.18786C19 6.73933 18.6364 6.37572 18.1879 6.37572H8.19997C7.89036 6.37572 7.59822 6.23231 7.40884 5.98738L6.9457 5.38834C6.75633 5.14341 6.46418 5 6.15458 5H2Z"
                  fill="#604DFF"
                />
                <path
                  d="M19 9C19 8.44772 18.5523 8 18 8H2C1.44772 8 1 8.44771 1 9V18C1 18.5523 1.44772 19 2 19H18C18.5523 19 19 18.5523 19 18V9Z"
                  fill="#7B6CFA"
                  stroke="#604DFF"
                />
                <path
                  d="M1 8V18C1 18.5523 1.44772 19 2 19H18C18.5523 19 19 18.5523 19 18V8M1 8V6C1 5.44772 1.44772 5 2 5H6.15458C6.46418 5 6.75633 5.14341 6.9457 5.38834L7.40884 5.98738C7.59822 6.23231 7.89036 6.37572 8.19997 6.37572H18C18.5523 6.37572 19 6.82344 19 7.37572V8M1 8H19"
                  stroke="#604DFF"
                />
              </svg>
            );
          })()}
        </IconButton>
      )}
    />
  );
}

export default React.memo(TableBodyExpandCell) as typeof TableBodyExpandCell;
