import React, { FC, useCallback, ReactNode, CSSProperties } from 'react';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Typography from 'components/Typography';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import ClearIcon from '@mui/icons-material/Clear';
import { DownloadCloudIcon } from 'components/Icons';
import useTranslation from 'hooks/useTranslation';
import InputClearIconButton from 'components/IconButton/InputClearIconButton';

export interface FileFieldProps extends DropzoneOptions {
  label?: ReactNode;
  onChange(files: File[]): void;
  error?: boolean;
  helperText?: ReactNode;
  name?: string;
  value?: File[];
  className?: string;
  classes?: {
    root?: string;
    controlsContainer?: string;
    uploadView?: string;
    uploadViewFilled?: string;
    uploadedFileName?: string;
    filesContainer?: string;
    helperText?: string;
  };
  style?: CSSProperties;
  fullWidth?: boolean;
}

const useStyles = makeStyles<{ fullWidth?: boolean }>()(
  (theme, { fullWidth }) => ({
    root: {
      display: 'inline-flex',
      flexDirection: 'column',
      outline: 'none',
      ...(fullWidth && {
        width: '100%',
      }),
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    uploadView: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: '12px',
      padding: 18,
      boxSizing: 'border-box',
      width: '100%',
      backgroundColor: theme.palette.primary.light,
      minHeight: 116,
      maxHeight: 116,
    },
    uploadViewFilled: {
      width: 'fit-content',
    },
    uploadViewError: {
      borderColor: theme.palette.error.main,
    },
    uploadedFileIcon: {
      color: theme.palette.text.secondary,
    },
    uploadedFileName: {
      marginLeft: 10,
      fontSize: 14,
      lineHeight: '28px',
      letterSpacing: '0.5px',
      color: theme.palette.text.secondary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    helperText: {
      marginTop: 3,
      marginRight: 14,
      fontSize: '0.75rem',
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: '1.66',
    },
    helperTextError: {
      color: theme.palette.error.main,
    },
    file: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      width: 'fit-content',
    },
    removeFileButton: {
      marginLeft: 5,
    },
    removeFileIcon: {
      fontSize: '1rem',
    },
    filesContainer: {
      marginLeft: 10,
    },
  }),
);

const FileField: FC<FileFieldProps> = ({
  label,
  onChange,
  error,
  helperText,
  name,
  multiple = true,
  value: files = [],
  className,
  classes,
  style,
  fullWidth,
  ...rest
}) => {
  const { classes: innerClasses, cx } = useStyles({ fullWidth });
  const { t } = useTranslation();

  const onDrop = useCallback(
    (newFiles: File[]) => {
      onChange(multiple ? [...files, ...newFiles] : newFiles);
    },
    [multiple, files, onChange],
  );

  const handleRemoveFile = useCallback(
    (fileIndex: number) => (event: any) => {
      event.stopPropagation();
      const newFilesState = files.filter((_, index) => index !== fileIndex);
      onChange(newFilesState);
    },
    [files, onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    ...rest,
  });

  return (
    <Box
      className={cx(className, innerClasses.root, classes?.root)}
      style={style}
      {...getRootProps()}
    >
      <Box
        className={cx(
          innerClasses.controlsContainer,
          classes?.controlsContainer,
        )}
      >
        <Box
          className={cx(innerClasses.uploadView, classes?.uploadView, {
            [innerClasses.uploadViewError]: error,
            [innerClasses.uploadViewFilled]: !!files.length,
            ...(classes?.uploadViewFilled && {
              [classes.uploadViewFilled]: !!files.length,
            }),
          })}
        >
          <DownloadCloudIcon
            color="primary"
            sx={{
              fontSize: '32px',
            }}
          />
          {!files.length &&
            (label || (
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="subtitle2"
                  component={'span'}
                  sx={theme => ({
                    color: theme.palette.primary.main,
                  })}
                >
                  {t('Click to upload')}{' '}
                </Typography>
                <Typography component={'span'} variant="body2">
                  {t('or drag and drop')}
                </Typography>
                <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
                  {(Array.isArray(rest.accept) ? rest.accept : [rest.accept])
                    .map(el => el?.substring(1))
                    .join(', ')}
                </Typography>
              </Box>
            ))}
        </Box>
        {!!files.length && (
          <Box
            className={cx(innerClasses.filesContainer, classes?.filesContainer)}
          >
            {files.map((file, index) => (
              <Box className={innerClasses.file} key={index}>
                <InsertDriveFileOutlinedIcon
                  className={innerClasses.uploadedFileIcon}
                />
                <Typography
                  className={cx(
                    innerClasses.uploadedFileName,
                    classes?.uploadedFileName,
                  )}
                  tooltip
                  tooltipProps={{ disableCopy: true }}
                >
                  {file.name}
                </Typography>
                <InputClearIconButton
                  className={innerClasses.removeFileButton}
                  onClick={handleRemoveFile(index)}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {helperText && (
        <Typography
          className={cx(innerClasses.helperText, classes?.helperText, {
            [innerClasses.helperTextError]: error,
          })}
        >
          {helperText}
        </Typography>
      )}
      <input name={name} {...getInputProps()} />
    </Box>
  );
};

export default FileField;
