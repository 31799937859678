import React, { FC } from 'react';
import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  Theme,
} from '@mui/material';

export interface DialogContentProps extends MuiDialogContentProps {}

const DialogContent: FC<DialogContentProps> = ({ children, sx, ...rest }) => {
  return (
    <MuiDialogContent
      {...rest}
      sx={[
        {
          padding: (theme: Theme) =>
            `${theme.spacing(3)} ${theme.spacing(2)}!important`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </MuiDialogContent>
  );
};

export default DialogContent;
