import React, { FC, HtmlHTMLAttributes, MutableRefObject } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import useSize from '@react-hook/size';
import LinearProgress from '@mui/material/LinearProgress';

interface TableLoaderProps extends HtmlHTMLAttributes<HTMLElement> {
  tableRef: MutableRefObject<any>;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    height: '100%',
    opacity: '0.2',
  },
}));

const TableLoader: FC<TableLoaderProps> = ({ tableRef }) => {
  const { classes } = useStyles();
  const [tableWidth, tableHeight] = useSize(tableRef);

  return (
    <LinearProgress
      className={classes.root}
      color="secondary"
      style={{
        width: tableWidth,
        height: tableHeight,
      }}
    />
  );
};

export default TableLoader;
