import React from 'react';
import { Theme, IconButton } from '@mui/material';
import TableBodyCell, { TableBodyCellProps } from './TableBodyCell';
import { makeStyles } from 'tss-react/mui';

interface TableBodyMultiSelectCellProps<T> extends TableBodyCellProps<T> {
  onMultiSelect: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles()(theme => ({
  controllerCell: {
    width: '1%',
  },
}));

function TableBodyMultiSelectCell<T>({
  row,
  onMultiSelect,
}: TableBodyMultiSelectCellProps<T>) {
  const { classes } = useStyles();

  return (
    <TableBodyCell
      className={classes.controllerCell}
      disabled={row.disabled}
      row={row}
      cell={() =>
        row.multiSelectable !== false && (
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              onMultiSelect(e);
            }}
          >
            {row.multiSelected ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM16.58 7.58L17.99 9L9.99 17L5.99 13.01L7.41 11.6L9.99 14.17L16.58 7.58Z"
                  fill="#604DFF"
                />
              </svg>
            ) : row.subTableRows &&
              Object.values(row.subTableRows).some(
                subTableRow => subTableRow.multiSelected,
              ) ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM17 11H7V13H17V11Z"
                  fill="#604DFF"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM19 19V5H5V19H19Z"
                  fill="#0000008a"
                />
              </svg>
            )}
          </IconButton>
        )
      }
    />
  );
}

export default React.memo(
  TableBodyMultiSelectCell,
) as typeof TableBodyMultiSelectCell;
