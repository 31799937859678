import React, { memo, useCallback } from 'react';
import { useField } from 'formik';
import DateTimePicker, {
  DateTimePickerProps,
} from '../DatePicker/DateTimePicker';
import useTranslation from 'hooks/useTranslation';
import { Dayjs } from 'dayjs';
import {
  DateTimeValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';

interface FormDateTimePickerPropsPart {
  name: string;
  onChange?: DateTimePickerProps['onChange'];
}

export type FormDatePickerProps = FormDateTimePickerPropsPart &
  Omit<
    DateTimePickerProps,
    keyof FormDateTimePickerPropsPart | 'value' | 'error'
  >;

const MemoDateTimePicker = memo(props => {
  return <DateTimePicker {...props} />;
}) as typeof DateTimePicker;

export default function FormDateTimePicker({
  name,
  onChange,
  slotProps,
  ...rest
}: FormDatePickerProps) {
  const [{ value, onBlur }, { error, touched }, { setValue, setTouched }] =
    useField<Dayjs | null | undefined>(name);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (
      date: Dayjs | null,
      context: PickerChangeHandlerContext<DateTimeValidationError>,
    ) => {
      !touched && setTouched(true);
      setValue(date);
      onChange?.(date, context);
    },
    [setValue, onChange, touched, setTouched],
  );

  const handleClose = useCallback(() => {
    !touched && setTouched(true);
  }, [touched, setTouched]);

  const showError = !!(error && touched);
  const helperText =
    error &&
    touched &&
    (typeof error === 'string'
      ? error
      : //@ts-ignore
        t(`yup:${error.key}`, error.values));

  return (
    <MemoDateTimePicker
      {...rest}
      value={value ?? null}
      onChange={handleChange}
      onClose={handleClose}
      slotProps={{
        ...slotProps,
        textField: {
          ...slotProps?.textField,
          name: name,
          onBlur,
          error: showError,
          helperText,
        },
      }}
    />
  );
}
