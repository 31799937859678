import React, {
  type Dispatch,
  type DragEvent,
  type SetStateAction,
  useRef,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import MenuItem, { type MenuItemProps } from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  type MRT_Column,
  type MRT_RowData,
  type MRT_TableInstance,
  reorderColumn,
  MRT_ColumnPinningButtons,
  MRT_GrabHandleButton,
} from 'material-react-table';
import { getCommonTooltipProps } from '../../utils/style.utils';
import { parseFromValuesOrFunc } from '../../utils/utils';
import { IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useTranslation from 'hooks/useTranslation';

interface MRT_ShowHideColumnsMenuItemsProps<TData extends MRT_RowData>
  extends MenuItemProps {
  allColumns: MRT_Column<TData>[];
  column: MRT_Column<TData>;
  hoveredColumn: MRT_Column<TData> | null;
  isNestedColumns: boolean;
  setHoveredColumn: Dispatch<SetStateAction<MRT_Column<TData> | null>>;
  table: MRT_TableInstance<TData>;
}

export const MRT_ShowHideColumnsMenuItems = <TData extends MRT_RowData>({
  allColumns,
  column,
  hoveredColumn,
  isNestedColumns,
  setHoveredColumn,
  table,
  ...rest
}: MRT_ShowHideColumnsMenuItemsProps<TData>) => {
  const { t } = useTranslation();
  const {
    getState,
    options: {
      enableColumnOrdering,
      enableHiding,
      enablePinning,
      mrtTheme: { draggingBorderColor },
    },
    setColumnOrder,
  } = table;
  const { columnOrder } = getState();
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const switchChecked =
    (columnDefType !== 'group' && column.getIsVisible()) ||
    (columnDefType === 'group' &&
      column.getLeafColumns().some(col => col.getIsVisible()));

  const handleToggleColumnHidden = (column: MRT_Column<TData>) => {
    if (columnDefType === 'group') {
      column?.columns?.forEach?.((childColumn: MRT_Column<TData>) => {
        childColumn.toggleVisibility(!switchChecked);
      });
    } else {
      column.toggleVisibility();
    }
  };

  const menuItemRef = useRef<HTMLElement>(null);

  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e: DragEvent<HTMLButtonElement>) => {
    setIsDragging(true);
    try {
      e.dataTransfer.setDragImage(menuItemRef.current as HTMLElement, 0, 0);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDragEnd = (_e: DragEvent<HTMLButtonElement>) => {
    setIsDragging(false);
    setHoveredColumn(null);
    if (hoveredColumn) {
      setColumnOrder(reorderColumn(column, hoveredColumn, columnOrder));
    }
  };

  const handleDragEnter = (_e: DragEvent) => {
    if (!isDragging && columnDef.enableColumnOrdering !== false) {
      setHoveredColumn(column);
    }
  };

  if (!columnDef.header || columnDef.visibleInShowHideMenu === false)
    return null;

  return (
    <>
      <MenuItem
        disableRipple
        onDragEnter={handleDragEnter}
        ref={menuItemRef as any}
        {...rest}
        sx={theme => ({
          alignItems: 'center',
          justifyContent: 'flex-start',
          my: 0,
          opacity: isDragging ? 0.5 : 1,
          outline: isDragging
            ? `2px dashed ${theme.palette.grey[500]}`
            : hoveredColumn?.id === column.id
            ? `2px dashed ${draggingBorderColor}`
            : 'none',
          outlineOffset: '-2px',
          pl: `${(column.depth + 0.5) * 2}rem`,
          py: '6px',
          ...(parseFromValuesOrFunc(rest?.sx, theme) as any),
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '8px',
            width: '100%',
          }}
        >
          {columnDefType !== 'group' &&
            enableColumnOrdering &&
            !isNestedColumns &&
            (columnDef.enableColumnOrdering !== false ? (
              <MRT_GrabHandleButton
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                table={table}
              />
            ) : (
              <Box sx={{ width: '28px' }} />
            ))}
          {
            //cc
            table.options.enableColumnPinning &&
              enablePinning !== false &&
              (column.getCanPin() ? (
                <MRT_ColumnPinningButtons column={column} table={table} />
              ) : (
                <Box sx={{ width: '70px' }} />
              ))
          }
          <Typography sx={{ alignSelf: 'center' }}>
            {columnDef.header}
          </Typography>
          {
            //cc
            enableHiding && column.getCanHide() && (
              <Tooltip
                {...getCommonTooltipProps()}
                arrow
                title={t('Hide')}
                sx={{ marginLeft: 'auto', width: 34, height: 34 }}
              >
                <IconButton
                  onClick={() => handleToggleColumnHidden(column)}
                  size="small"
                >
                  <VisibilityOffIcon />
                </IconButton>
              </Tooltip>
            )
          }
        </Box>
      </MenuItem>
      {column.columns
        //cc
        ?.filter(column => column.columnDef.columnDefType !== 'display')
        .map((c: MRT_Column<TData>, i) => (
          <MRT_ShowHideColumnsMenuItems
            allColumns={allColumns}
            column={c}
            hoveredColumn={hoveredColumn}
            isNestedColumns={isNestedColumns}
            key={`${i}-${c.id}`}
            setHoveredColumn={setHoveredColumn}
            table={table}
          />
        ))}
    </>
  );
};
