import React, { FC, useCallback, useState, useEffect, ReactNode } from 'react';
import {
  FormControl,
  FormControlProps as MuiFormControlProps,
  InputLabel,
  InputLabelProps,
  FormHelperText,
  FormHelperTextProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Box,
  Theme,
  SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputClearIconButton from '../IconButton/InputClearIconButton';

interface SelectPropsPart {
  value: any;
  onChange: (value: any) => void;
  onOpen?: () => void;
  onClose?: () => void;
  label?: string;
  helperText?: ReactNode;
  labelProps?: InputLabelProps;
  formHelperTextProps?: FormHelperTextProps;
  clearable?: boolean;
}
interface FormControlProps
  extends Omit<
    MuiFormControlProps,
    keyof MuiSelectProps | keyof SelectPropsPart
  > {}

export interface SelectProps
  extends Omit<MuiSelectProps, keyof SelectPropsPart>,
    SelectPropsPart,
    FormControlProps {}

const useStyles = makeStyles<{ disabled: SelectProps['disabled'] }>()(
  (theme, { disabled }) => ({
    root: {},
    selectOutlined: {
      paddingRight: '0px!important',
    },
    label: {},
    labelOutlined: {},
    labelShrink: {},
    helperText: {
      marginLeft: 0,
    },
    clear: {
      marginRight: '4px',
    },
    keyboardArrowDownIcon: {
      fontSize: '1.25rem',
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      ...(disabled && {
        color: theme.palette.action.disabled,
        cursor: 'unset',
      }),
    },
  }),
);

const Select: FC<SelectProps> = ({
  className,
  style,
  color,
  disabled,
  error,
  focused,
  fullWidth,
  hiddenLabel,
  margin,
  required,
  variant = 'outlined',
  label,
  helperText,
  key,
  labelProps,
  formHelperTextProps,
  size = 'small',
  inputProps,
  value,
  onChange,
  clearable,
  open,
  onOpen,
  onClose,
  ...selectProps
}) => {
  const { classes: innerClasses, cx } = useStyles({ disabled });
  const [innerOpen, setInnerOpen] = useState(false);
  const isValueEmpty = selectProps.multiple ? value.length === 0 : value === '';

  useEffect(() => {
    setInnerOpen(open === undefined ? false : open);
  }, [open]);

  const handleClose = useCallback(() => {
    if (disabled) return;

    if (open === undefined) setInnerOpen(false);
    onClose?.();
  }, [open, onClose, disabled]);

  const handleOpen = useCallback(() => {
    if (disabled) return;

    if (open === undefined) setInnerOpen(true);
    onOpen?.();
  }, [open, onOpen, disabled]);

  const handleToggle = useCallback(() => {
    if (innerOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  }, [innerOpen, handleClose, handleOpen]);

  const handleClear = useCallback(() => {
    if (isValueEmpty) return;

    onChange(selectProps.multiple ? [] : '');
  }, [isValueEmpty, onChange, selectProps.multiple, disabled]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<any>) => onChange(event.target.value),
    [onChange],
  );

  return (
    <FormControl
      size={size}
      color={color}
      disabled={disabled}
      error={error}
      focused={focused}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      margin={margin}
      required={required}
      variant={variant}
      style={style}
      key={key}
      className={cx(className, innerClasses.root)}
    >
      <InputLabel
        {...labelProps}
        classes={{
          ...labelProps?.classes,
          root: innerClasses.label,
          outlined: cx(
            labelProps?.classes?.outlined,
            innerClasses.labelOutlined,
          ),
          shrink: cx(labelProps?.classes?.shrink, innerClasses.labelShrink),
        }}
        error={false}
      >
        {label}
      </InputLabel>
      <MuiSelect
        {...selectProps}
        value={value}
        onChange={handleChange}
        label={label}
        classes={{
          ...selectProps?.classes,
          outlined: cx(
            selectProps?.classes?.outlined,
            innerClasses.selectOutlined,
          ),
        }}
        open={innerOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={
          selectProps.IconComponent ||
          (() => (
            <Box
              display="flex"
              alignItems="center"
              style={{
                padding: '0px 6px',
              }}
            >
              {value && clearable && (
                <InputClearIconButton
                  className={innerClasses.clear}
                  hidden={isValueEmpty}
                  onClick={handleClear}
                  disabled={disabled}
                />
              )}
              <KeyboardArrowDownIcon
                className={innerClasses.keyboardArrowDownIcon}
                onClick={handleToggle}
              />
            </Box>
          ))
        }
      />
      {helperText && (
        <FormHelperText
          {...formHelperTextProps}
          classes={{
            ...formHelperTextProps?.classes,
            root: cx(
              formHelperTextProps?.classes?.root,
              innerClasses.helperText,
            ),
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
