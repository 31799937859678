import React, { FC, forwardRef } from 'react';
import {
  ButtonLast as PRCButtonLast,
  ButtonLastProps,
  WithStore,
} from 'pure-react-carousel';

interface StateData {
  currentSlide: number;
  totalSlides: number;
  visibleSlides: number;
}

interface IButtonLast extends ButtonLastProps {
  hideOnNoSteps?: boolean;
}

const ButtonLast: FC<IButtonLast & StateData> = forwardRef(
  (props, ref: any) => {
    const {
      hideOnNoSteps = false,
      currentSlide,
      totalSlides,
      visibleSlides,
      ...rest
    } = props;

    if (hideOnNoSteps && currentSlide + visibleSlides >= totalSlides) {
      return null;
    }

    return <PRCButtonLast ref={ref} {...rest} />;
  },
);

// @ts-ignore
export default WithStore<IButtonLast, StateData>(ButtonLast, state => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides,
  visibleSlides: state.visibleSlides,
}));
