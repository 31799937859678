import React, { FC, useCallback, memo } from 'react';
import FileField, { FileFieldProps } from '../FileField';
import { useField } from 'formik';
import useTranslation from 'hooks/useTranslation';

type FormFileFieldPropsPart = {
  name: string;
  onChange?(files: File[]): void;
};

export type FormFileFieldProps = FormFileFieldPropsPart &
  Omit<
    FileFieldProps,
    keyof FormFileFieldPropsPart | 'error' | 'value' | 'helperText'
  >;

const MemoFileField: FC<FileFieldProps> = memo(props => (
  <FileField {...props} />
));

const FormFileField: FC<FormFileFieldProps> = ({ name, onChange, ...rest }) => {
  const [, { error, touched, value }, { setValue, setTouched }] =
    useField(name);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (files: File[]) => {
      if (!touched) {
        setTouched(true);
      }
      setValue(files);
      onChange?.(files);
    },
    [touched, setTouched, setValue, onChange],
  );

  return (
    <MemoFileField
      {...rest}
      name={name}
      onChange={handleChange}
      error={!!(error && touched)}
      value={value}
      helperText={
        error &&
        touched &&
        (typeof error === 'string'
          ? error
          : //@ts-ignore
            t(`yup:${error.key}`, error.values))
      }
    />
  );
};

export default FormFileField;
