import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { Dayjs } from 'dayjs';
import DatePicker, { DatePickerProps } from './DatePicker';

type DatePickerRestrictedProps = Omit<DatePickerProps, 'onChange' | 'value'>;

export interface DatePickerRangeProps extends Omit<BoxProps, 'onChange'> {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  onChange: (newDates: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }) => void;
  startDatePickerProps?: DatePickerRestrictedProps;
  endDatePickerProps?: DatePickerRestrictedProps;
}

export default function DatePickerRange({
  onChange,
  startDate,
  endDate,
  startDatePickerProps,
  endDatePickerProps,
  ...rest
}: DatePickerRangeProps) {
  return (
    <Box display="flex" alignItems="center" {...rest}>
      <DatePicker
        value={startDate}
        onChange={(newStartDate: Dayjs | null) => {
          onChange({
            startDate: newStartDate?.startOf('day') ?? null,
            endDate,
          });
        }}
        {...startDatePickerProps}
      />
      <Box
        sx={{
          width: '10px',
          borderTop: '2px solid #737A96',
          margin: '0px 12px',
        }}
      />
      <DatePicker
        value={endDate}
        onChange={(newEndDate: Dayjs | null) => {
          onChange({
            startDate,
            endDate: newEndDate?.endOf('day') ?? null,
          });
        }}
        {...endDatePickerProps}
      />
    </Box>
  );
}
