import React, { FC, forwardRef } from 'react';
import {
  ButtonFirst as PRCButtonFirst,
  ButtonFirstProps,
  WithStore,
} from 'pure-react-carousel';

interface StateData {
  currentSlide: number;
}

interface IButtonFirst extends ButtonFirstProps {
  hideOnNoSteps?: boolean;
}

const ButtonFirst: FC<IButtonFirst & StateData> = forwardRef(
  (props, ref: any) => {
    const { hideOnNoSteps = false, currentSlide, ...rest } = props;

    if (hideOnNoSteps && currentSlide === 0) {
      return null;
    }

    return <PRCButtonFirst ref={ref} {...rest} />;
  },
);

// @ts-ignore
export default WithStore<IButtonFirst, StateData>(ButtonFirst, state => ({
  currentSlide: state.currentSlide,
}));
