import React, { FC, forwardRef } from 'react';
import {
  ButtonBack as PRCButtonBack,
  ButtonBackProps,
  WithStore,
} from 'pure-react-carousel';
interface StateData {
  currentSlide: number;
}

interface IButtonBack extends ButtonBackProps {
  hideOnNoSteps?: boolean;
}

const ButtonBack: FC<IButtonBack & StateData> = forwardRef(
  (props, ref: any) => {
    const { hideOnNoSteps = false, currentSlide, ...rest } = props;

    if (hideOnNoSteps && currentSlide === 0) {
      return null;
    }

    return <PRCButtonBack ref={ref} {...rest} />;
  },
);

// @ts-ignore
export default WithStore<IButtonBack, StateData>(ButtonBack, state => ({
  currentSlide: state.currentSlide,
}));
