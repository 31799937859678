import React, { FC, useCallback, useRef } from 'react';
import { Box, BoxProps } from '@mui/material';
import { combineSX } from '../..';
import ResizeSlider, { RESIZER_SLIDER_SIZE } from './ResizeSlider';

export interface ResizerProps
  extends Omit<BoxProps, 'top' | 'right' | 'bottom' | 'left' | 'onResize'> {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  width?: number;
  maxWidth?: number;
  onResize?: (params: { width: number; height: number }) => void;
}

const Resizer: FC<ResizerProps> = ({
  top,
  right,
  bottom,
  left,
  width,
  maxWidth,
  onResize,
  children,
  sx,
}) => {
  const resizableRef = useRef<HTMLDivElement>(null);
  const stateRef = useRef<{
    x: number;
    w: number;
    y: number;
    h: number;
    position: null | 'top' | 'right' | 'bottom' | 'left';
  }>({ x: 0, w: 0, y: 0, h: 0, position: null });

  const handleResize = useCallback(
    (e: MouseEvent) => {
      if (
        stateRef.current.position === 'left' ||
        stateRef.current.position === 'right'
      ) {
        const diff = e.clientX - stateRef.current.x;
        const calcWidth =
          stateRef.current.w +
          (stateRef.current.position === 'left' ? -diff : +diff);
        const newWidth = (() => {
          if (calcWidth < 0) return 0;
          if (maxWidth && calcWidth > maxWidth) return maxWidth;
          return calcWidth;
        })();
        resizableRef.current!.style.width = `${newWidth}px`;
        resizableRef.current!.style.minWidth = `${newWidth}px`;

        onResize?.({
          width: newWidth,
          height: stateRef.current.h,
        });
      } else {
        const diff = e.clientY - stateRef.current.y;
        const calcHeight =
          stateRef.current.h +
          (stateRef.current.position === 'top' ? -diff : +diff);
        const newHeight = calcHeight > 0 ? calcHeight : 0;
        resizableRef.current!.style.height = `${newHeight}px`;
        resizableRef.current!.style.minHeight = `${newHeight}px`;

        onResize?.({
          width: stateRef.current.w,
          height: newHeight,
        });
      }
    },
    [top, right, bottom, left, maxWidth],
  );

  const handleResizeEnd = useCallback(() => {
    document.removeEventListener('mousemove', handleResize);
    document.removeEventListener('mouseup', handleResizeEnd);
  }, []);

  const handleResizeStart = useCallback(
    (e: React.MouseEvent, position: 'top' | 'right' | 'bottom' | 'left') => {
      e.preventDefault();
      stateRef.current.position = position;
      stateRef.current.x = e.clientX;
      stateRef.current.y = e.clientY;
      const computedStyle = window.getComputedStyle(
        resizableRef.current as HTMLDivElement,
      );
      stateRef.current.w = parseInt(computedStyle.width, 10);
      stateRef.current.h = parseInt(computedStyle.height, 10);
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleResizeEnd);
    },
    [],
  );

  return (
    <Box
      ref={resizableRef}
      sx={combineSX(
        {
          width,
          minWidth: width,
          position: 'relative',
          '&>:first-of-type': {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
          },
          ...(top && {
            mt: `${RESIZER_SLIDER_SIZE}px`,
          }),
          ...(right && {
            mr: `${RESIZER_SLIDER_SIZE}px`,
          }),
          ...(bottom && {
            mb: `${RESIZER_SLIDER_SIZE}px`,
          }),
          ...(left && {
            ml: `${RESIZER_SLIDER_SIZE}px`,
          }),
        },
        sx,
      )}
    >
      {children}
      {top && <ResizeSlider position="top" onStartResize={handleResizeStart} />}
      {right && (
        <ResizeSlider position="right" onStartResize={handleResizeStart} />
      )}
      {bottom && (
        <ResizeSlider position="bottom" onStartResize={handleResizeStart} />
      )}
      {left && (
        <ResizeSlider position="left" onStartResize={handleResizeStart} />
      )}
    </Box>
  );
};

export default Resizer;
