import React, { FC, useState, useRef } from 'react';
import {
  Box,
  Popover,
  Theme,
  useMediaQuery,
  BoxProps,
  Avatar,
  Button,
} from '@mui/material';
import Typography from 'components/Typography';
import { User } from 'components/Layout/types';
import UserInfo from './UserInfo';
import SwipeableDrawer from 'components/Drawer/SwipeableDrawer';
import { useLayoutContext } from 'components/Layout/LayoutContext';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';

interface UserMenuProps extends BoxProps {
  user: User;
  renderMenuContent: (close: () => void) => React.ReactNode;
}

const UserMenu: FC<UserMenuProps> = ({ user, renderMenuContent, ...rest }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const isLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('laptop'),
    {
      noSsr: true,
    },
  );
  const {
    layoutState: { sidebarOpen },
  } = useLayoutContext();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Box {...rest}>
      <Button
        variant="text"
        onClick={handleOpen}
        ref={ref}
        fullWidth
        sx={theme => ({
          ...(open
            ? {
                background: 'white',
                color: theme.palette.text.primary,
              }
            : {
                color: 'white',
              }),
          justifyContent: 'start',
          minWidth: 'unset',
          padding: '8px 9px',
        })}
      >
        <Avatar sx={{ width: 24, height: 24, bgcolor: 'white' }}>
          <FaceOutlinedIcon color="primary" />
        </Avatar>
        {sidebarOpen && (
          <>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                ml: 1,
                textTransform: 'capitalize',
              }}
            >
              {user?.name} {user?.surname}
            </Typography>
          </>
        )}
      </Button>
      {isLaptop ? (
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 30,
          }}
          anchorEl={ref.current}
          keepMounted
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              sx: {
                padding: '16px',
                borderRadius: '8px',
              },
            },
          }}
        >
          <UserInfo user={user} />
          {renderMenuContent(handleClose)}
        </Popover>
      ) : (
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          anchor="right"
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          sx={{
            '& .MuiDrawer-paper': {
              width: '100%',
            },
          }}
        >
          <UserInfo user={user} />
          {renderMenuContent(handleClose)}
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default UserMenu;
