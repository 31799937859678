import React, { FC } from 'react';
import MuiToggleButton, {
  ToggleButtonProps as MuiToggleButtonProps,
} from '@mui/material/ToggleButton';

interface ToggleButtonPropsPart {
  label?: string | React.ReactNode;
}

export type ToggleButtonProps = ToggleButtonPropsPart &
  Omit<MuiToggleButtonProps, keyof ToggleButtonPropsPart>;

const ToggleButton: FC<ToggleButtonProps> = ({ label, children, ...rest }) => {
  return <MuiToggleButton {...rest}>{label ?? children}</MuiToggleButton>;
};

export default ToggleButton;
