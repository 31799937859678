import React, { FC, memo, useCallback } from 'react';
import Checkbox, { CheckboxProps } from '../Checkbox';
import { useField, useFormikContext } from 'formik';
import get from 'lodash/get';

interface FormCheckboxProps extends CheckboxProps {
  name: string;
}

const MemoCheckbox: FC<FormCheckboxProps> = memo(props => (
  <Checkbox {...props} />
));

const FormCheckbox: FC<FormCheckboxProps> = ({ name, onChange, ...rest }) => {
  const [{ value }, , { setValue }] = useField<boolean | null | undefined>(
    name,
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setValue(checked);
      onChange?.(e, checked);
    },
    [setValue, onChange],
  );

  return (
    <MemoCheckbox
      name={name}
      checked={value ?? false}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default FormCheckbox;
