import React, { FC, forwardRef } from 'react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Tooltip,
  TooltipProps,
  CircularProgress,
} from '@mui/material';
import { combineSX } from 'helpers';

type IconButtonPropsPart = {
  tooltip?: TooltipProps['title'] | null;
  tooltipProps?: Partial<Omit<TooltipProps, 'title'>>;
  loading?: boolean;
  color?: Exclude<MuiIconButtonProps['color'], 'default'>;
  padding?: boolean;
};

export type IconButtonProps = IconButtonPropsPart &
  Omit<MuiIconButtonProps, keyof IconButtonPropsPart>;

const IconButton: FC<IconButtonProps> = forwardRef(
  (
    {
      size = 'medium',
      tooltip,
      tooltipProps,
      loading = false,
      padding,
      children,
      sx,
      ...rest
    },
    ref,
  ) => {
    const iconButton = (
      <MuiIconButton
        ref={ref}
        {...rest}
        disabled={rest.disabled || loading}
        size={size}
        sx={combineSX(
          {
            position: 'relative',
            ...(!padding && {
              margin: {
                small: '-3px',
                medium: '-6px',
                large: '-9px',
              }[size],
            }),
            padding: {
              small: '3px',
              medium: '6px',
              large: '9px',
            }[size],
          },
          sx,
        )}
      >
        {children}
        {loading && (
          <CircularProgress
            size={32}
            color="primary"
            sx={{
              position: 'absolute',
              top: 2,
              left: 2,
              zIndex: 1,
            }}
          />
        )}
      </MuiIconButton>
    );

    return tooltip && !rest.disabled ? (
      <Tooltip title={tooltip} disableInteractive {...tooltipProps}>
        {iconButton}
      </Tooltip>
    ) : (
      <>{iconButton}</>
    );
  },
);

export default IconButton;
