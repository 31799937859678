import React, { FC, ReactNode, memo } from 'react';
import {
  Theme,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { combineSX } from '../../..';
import { Row } from '../Table';

export interface TableBodyCellProps<T> extends MuiTableCellProps {
  disabled?: boolean;
  width?: number;
  actionsColumn?: boolean;
  identifier?: string;
  cell?: (row: Row<T>) => React.ReactNode;
  row: Row<T>;
}

const useStyles = makeStyles()((theme: Theme) => ({
  disabled: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 2,
    background: `${theme.palette.surface.disabled}90`,
    left: 0,
    top: 0,
  },
}));

function TableBodyCell<T>({
  disabled,
  children,
  width,
  sx,
  actionsColumn,
  identifier,
  cell,
  row,
  ...rest
}: TableBodyCellProps<T>) {
  const { classes } = useStyles();

  return (
    <MuiTableCell
      sx={combineSX(
        theme => ({
          ...theme.typography.body1,
          position: 'relative',
          py: 0.5,
          px: 1.5,
          '&:first-of-type': {
            paddingLeft: 3,
          },
          '&:last-child': {
            paddingRight: actionsColumn ? 1.5 : 3,
          },
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          ...(width && {
            minWidth: width,
            maxWidth: width,
          }),
          ...(actionsColumn && {
            width: '1%',
            position: 'sticky',
            right: 0,
            background: 'white',
            borderLeft: `1px solid ${theme.palette.divider}`,
          }),
        }),
        sx,
      )}
      {...rest}
    >
      {disabled && <div className={classes.disabled} />}
      {(cell?.(row) ?? row.data[identifier as keyof T]) as ReactNode}
    </MuiTableCell>
  );
}
export default memo(TableBodyCell) as typeof TableBodyCell;
