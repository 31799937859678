import React, { FC } from 'react';
import {
  ListItemIcon as MUIListItemIcon,
  ListItemIconProps,
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  listIcon: {
    minWidth: 28,
  },
});

const ListItemIcon: FC<ListItemIconProps> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIListItemIcon className={classes.listIcon} {...rest}>
      {children}
    </MUIListItemIcon>
  );
};

export default ListItemIcon;
