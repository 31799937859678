import React, { FC } from 'react';
import {
  SwipeableDrawer as MuiSwipeableDrawer,
  SwipeableDrawerProps as MuiSwipeableDrawerProps,
  Theme,
  useMediaQuery,
} from '@mui/material';
import IconButton from 'components/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface SwipeableDrawerProps extends MuiSwipeableDrawerProps {}

const SwipeableDrawer: FC<SwipeableDrawerProps> = ({
  onClose,
  children,
  ...rest
}) => {
  const isLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('laptop'),
    {
      noSsr: true,
    },
  );

  return (
    <MuiSwipeableDrawer onClose={onClose} {...rest}>
      {!isLaptop && (
        <IconButton
          className="LuiDrawer-closeIcon"
          sx={theme => ({
            position: 'absolute',
            right: 16,
            top: 8,
          })}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </MuiSwipeableDrawer>
  );
};

export default SwipeableDrawer;
