import React, {
  ComponentType,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Theme, InputBaseProps, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import MessageComponent, { MessageProps } from './components/Message';
import { SendPlaneIcon } from 'components/Icons';
import useTranslation from 'hooks/useTranslation';
import ListItemIcon from 'components/Menus/ListItemIcon';
import ListItemText from 'components/Menus/ListItemText';
import IconButton from 'components/IconButton';
import ThreeDotsMenu from 'components/Menus/ThreeDotsMenu';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      height: '100%',
    },
    body: {
      flexGrow: 1,
      backgroundColor: '#fff',
      padding: theme.spacing(2),
      overflowY: 'hidden',
      paddingRight: theme.spacing(2),

      '&:hover': {
        paddingRight: 0,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: theme.spacing(2),
        },
        '&::-webkit-scrollbar-thumb': {
          height: theme.spacing(3),
          border: '4px solid rgba(0, 0, 0, 0)',
          backgroundClip: 'padding-box',
          borderRadius: 7,
          backgroundColor: '#d6dbe2',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
      },
    },
    input: {
      minHeight: 56,
      height: '100%',
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: '0px 16px 0px 16px',
    },
  };
});

export interface ChatProps extends PropsWithChildren {
  subTitle?: string;
  messages: {
    author?: string;
    message: string;
    inner: boolean;
    date: string;
    remark?: boolean;
  }[];
  Header?: ReactElement;
  onSend: (text: string, remark?: boolean) => void;
  classes?: {
    input: string;
    messageList: string;
  };
  Message?: ComponentType<MessageProps>;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onClose?: () => void;
  onHide?: () => void;
  messageInputProps?: InputBaseProps;
  disableRemark?: boolean;
}

const Chat: FC<ChatProps> = ({
  Header,
  messages = [],
  onSend,
  classes,
  Message = MessageComponent,
  children,
  readonly = false,
  disabled = false,
  placeholder = 'Your message',
  onClose,
  onHide,
  messageInputProps,
  disableRemark,
  ...rest
}) => {
  const { classes: innerClasses, cx } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const messagesList = useRef<HTMLDivElement>(null);
  const lastElement = useRef<any>(null);
  const [shouldScroll, setShouldScroll] = useState(true);
  const isDisabled = readonly || disabled;

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach((entry: any) => {
          setShouldScroll(entry.isIntersecting);
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      },
    );
    if (lastElement.current) {
      observer.observe(lastElement.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const [text, setText] = useState('');

  const scrollBottom = useCallback((behavior: ScrollBehavior = 'smooth') => {
    messagesList.current?.scrollTo({
      top: messagesList.current?.scrollHeight,
      behavior,
    });
    setShouldScroll(true);
  }, []);

  const handleChange = useCallback(
    (e: any) => {
      setText(e.target.value);
    },
    [setText],
  );

  const handleSubmit = useCallback(
    (remark?: boolean) => {
      if (text.trim() === '') {
        return;
      }
      onSend(text, remark);
      setText('');
      setTimeout(scrollBottom);
    },
    [onSend, scrollBottom, text],
  );

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  useEffect(() => {
    if (shouldScroll) {
      scrollBottom();
    }
  }, [scrollBottom, messages, shouldScroll]);

  useEffect(() => {
    scrollBottom('auto');
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      direction="column"
      container
      className={innerClasses.container}
      wrap="nowrap"
      {...rest}
    >
      {Header && React.cloneElement(Header, { onClose, onHide })}

      <Grid
        item
        className={cx(innerClasses.body, classes?.messageList)}
        ref={messagesList}
      >
        {children}
        {messages.map((message, index) => (
          <Message key={index} {...message} />
        ))}
        <span style={{ visibility: 'hidden' }} ref={lastElement} />
      </Grid>
      <Grid
        item
        sx={{
          borderRadius: '0 0 4px 4px',
          flexShrink: 0,
          px: 2,
          pb: 2,
        }}
      >
        <InputBase
          className={cx(innerClasses.input, classes?.input)}
          fullWidth
          multiline
          autoFocus={isDesktop}
          maxRows={5}
          value={text}
          disabled={isDisabled}
          inputProps={{
            onChange: handleChange,
            onKeyDown: handleKeyDown,
            sx: {
              overflowY: 'hidden',
            },
          }}
          placeholder={placeholder}
          endAdornment={
            <>
              <InputAdornment position="end">
                <IconButton
                  aria-label="Send message"
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={isDisabled}
                  size="medium"
                >
                  <SendPlaneIcon />
                </IconButton>
              </InputAdornment>
              {!disableRemark && (
                <ThreeDotsMenu
                  options={[
                    {
                      content: (
                        <>
                          <ListItemIcon>
                            <SendPlaneIcon
                              sx={theme => ({
                                color: theme.palette.text.secondary,
                              })}
                            />
                          </ListItemIcon>
                          <ListItemText primary={t('Create Remark')} />
                        </>
                      ),
                      action: close => {
                        handleSubmit(true);
                        close();
                      },
                    },
                  ]}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  sx={{ ml: 1 }}
                />
              )}
            </>
          }
          {...messageInputProps}
        />
      </Grid>
    </Grid>
  );
};

export default Chat;
