import * as React from 'react';
import { styled } from '@mui/system';
import { useFormikContext } from 'formik';

const StyledForm = styled('form')({});

export type FormProps = Parameters<typeof StyledForm>[0];

export default (props: FormProps) => {
  const formikProps = useFormikContext();

  return (
    <StyledForm
      {...props}
      onReset={e => {
        formikProps.handleReset(e);
        props.onReset?.(e);
      }}
      onSubmit={e => {
        formikProps.handleSubmit(e);
        props.onSubmit?.(e);
      }}
    />
  );
};
