import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';

interface ResizeSliderProps extends Omit<BoxProps, 'position'> {
  position: 'top' | 'right' | 'bottom' | 'left';
  onStartResize: (
    e: React.MouseEvent,
    position: 'top' | 'right' | 'bottom' | 'left',
  ) => void;
}

export const RESIZER_SLIDER_SIZE = 18;

const ResizeSlider: FC<ResizeSliderProps> = ({ position, onStartResize }) => {
  return (
    <Box
      onMouseDown={e => onStartResize(e, position)}
      sx={{
        border: '1px solid #D9DBE1',
        position: 'absolute',
        background: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(position === 'top' || position === 'bottom'
          ? {
              height: RESIZER_SLIDER_SIZE,
              width: '100%',
              cursor: 'row-resize',
              left: 0,
            }
          : {
              width: RESIZER_SLIDER_SIZE,
              height: '100%',
              cursor: 'col-resize',
              top: 0,
            }),
        [position]: -RESIZER_SLIDER_SIZE,
      }}
    >
      <Box
        sx={{
          ...(position === 'top' || position === 'bottom'
            ? {
                height: 4,
                width: 86,
              }
            : {
                width: 4,
                height: 86,
              }),
          background: '#969BAB',
          borderRadius: 6,
        }}
      />
    </Box>
  );
};

export default ResizeSlider;
