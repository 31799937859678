import React, { FC, memo, useCallback } from 'react';
import TextField, { TextFieldProps } from '../TextField';
import { useField } from 'formik';
import useTranslation from 'hooks/useTranslation';

interface FormTextFieldProps extends TextFieldProps {
  name: string;
}

const MemoTextField: FC<TextFieldProps> = memo(props => (
  <TextField {...props} />
));

const FormTextField: FC<FormTextFieldProps> = ({
  name,
  onChange,
  onBlur,
  ...rest
}) => {
  const [{ value, onBlur: formikOnBlur }, { error, touched }, { setValue }] =
    useField<string | number | null | undefined>(name);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = (() => {
        if (rest.type === 'number') {
          return e.target.value === '' ? null : Number(e.target.value);
        }

        return e.target.value;
      })();
      setValue(newValue);
      onChange?.(e);
    },
    [setValue, touched, onChange, rest.type],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      formikOnBlur(e);
      onBlur?.(e);
    },
    [onBlur, formikOnBlur],
  );

  return (
    <MemoTextField
      name={name}
      value={value ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!(error && touched)}
      helperText={
        error &&
        touched &&
        (typeof error === 'string'
          ? error
          : //@ts-ignore
            t(`yup:${error.key}`, error.values))
      }
      {...rest}
    />
  );
};

export default FormTextField;
