import React from 'react';

import Progress from '../Progress';
import { Grid } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    flexGrow: 1,
  },
});

const Loader: React.FC = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item>
          <Progress />
        </Grid>
      </Grid>
    </div>
  );
};

export default Loader;
