import React, { FC } from 'react';
import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';
import { combineSX } from 'helpers';

interface RadioGroupProps extends MuiRadioGroupProps {}

const RadioGroup: FC<RadioGroupProps> = ({ sx, ...rest }) => {
  return (
    <MuiRadioGroup
      sx={combineSX(
        {
          gap: 1,
        },
        sx,
      )}
      {...rest}
    />
  );
};

export default RadioGroup;
