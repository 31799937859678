import React, { FC, forwardRef, useState } from 'react';
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  Tooltip,
  TooltipProps,
  Theme,
  tooltipClasses,
  styled,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Fade from '@mui/material/Fade';
import CopyToClipboard from 'react-copy-to-clipboard';

type TypographyPropsPart = {
  tooltip?: boolean | string;
  tooltipProps?: Partial<Omit<TooltipProps, 'title'>> & {
    disableCopy?: boolean;
  };
};

export type TypographyProps = TypographyPropsPart &
  Omit<MuiTypographyProps, keyof TypographyPropsPart>;

const useStyles = makeStyles()(theme => ({
  root: {},
  tooltipRoot: {
    display: 'flex',
    gap: theme.spacing(),
    alignItems: 'center',
  },
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    wordBreak: 'break-all',
  },
});

const Typography: FC<TypographyProps> = forwardRef(
  (
    {
      classes,
      tooltip,
      tooltipProps: {
        disableCopy: disableTooltipCopy,
        ...originalTooltipProps
      } = {
        disableCopy: false,
      },
      ...rest
    },
    ref,
  ) => {
    const { classes: innerClasses, cx } = useStyles();

    const [isOpen, setIsOpen] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const typography = (
      <MuiTypography
        classes={{
          ...classes,
          root: cx(classes?.root, innerClasses.root),
        }}
        ref={ref}
        {...rest}
      />
    );

    if (tooltip && rest.children) {
      const tooltipText =
        typeof tooltip === 'boolean' ? (rest.children as string) : tooltip;
      return (
        <StyledTooltip
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => {
            setIsOpen(false);
            setTimeout(() => setIsCopied(false), 400);
          }}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <div
              className={innerClasses.tooltipRoot}
              onClick={e => e.stopPropagation()}
            >
              {tooltipText}
              {!disableTooltipCopy &&
                (isCopied ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <CopyToClipboard
                    text={tooltipText}
                    onCopy={() => setIsCopied(true)}
                  >
                    <FileCopyOutlinedIcon
                      fontSize="small"
                      style={{ cursor: 'pointer' }}
                    />
                  </CopyToClipboard>
                ))}
            </div>
          }
          placement="bottom-start"
          {...originalTooltipProps}
        >
          {typography}
        </StyledTooltip>
      );
    }

    return typography;
  },
);

export default Typography;
