import { useTranslation as i18nUseTranslation } from 'react-i18next';
import i18n from 'i18n';

const useTranslation = (namespace = 'common') => {
  return i18nUseTranslation(namespace, {
    i18n,
  });
};

export default useTranslation;
