import React, { FC } from 'react';
import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material';
import { combineSX } from 'helpers';

interface FormControlLabelProps extends MuiFormControlLabelProps {}

const FormControlLabel: FC<FormControlLabelProps> = ({ sx, ...rest }) => {
  return (
    <MuiFormControlLabel
      sx={combineSX(
        {
          m: 0,
          '& .MuiFormControlLabel-label': {
            ml: 1,
          },
        },
        sx,
      )}
      {...rest}
    />
  );
};

export default FormControlLabel;
