import React, { FC } from 'react';
import { BoxProps } from '@mui/material';
import { PageContainer } from '../..';

interface PageContainerSkeletonProps extends BoxProps {}

const PageContainerSkeleton: FC<PageContainerSkeletonProps> = () => {
  return <PageContainer title={null} loading />;
};

export default PageContainerSkeleton;
