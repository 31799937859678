import React, { FC } from 'react';

import Chat, { ChatProps } from './Chat';
import DialogChat, { DialogChatProps } from './DialogChat';

export { default as Message } from './components/Message';
export { default as ChatHeader } from './ChatHeader';

export type { ChatProps } from './Chat';
export type { DialogChatProps, Offset, Position } from './DialogChat';

interface ChatBoxProps extends ChatProps, DialogChatProps {
  dialog?: boolean;
}

const ChatBox: FC<ChatBoxProps> = ({ dialog = false, ...rest }) => {
  const Component = dialog ? DialogChat : Chat;

  return <Component {...rest} />;
};

export default ChatBox;
