import React from 'react';
import { Grid, GridProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type ActionBarItemProps = GridProps;

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ActionBarItem = ({ className, ...rest }: ActionBarItemProps) => {
  const { classes, cx } = useStyles();

  return <Grid className={cx(className, classes.root)} item {...rest} />;
};

export default ActionBarItem;
