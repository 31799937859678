import React, { FC } from 'react';
import {
  CheckboxProps as MuiCheckboxProps,
  useTheme,
  Tooltip,
  TooltipProps,
  SvgIcon,
} from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import { combineSX } from 'helpers';

export interface CheckboxProps extends Omit<MuiCheckboxProps, 'size'> {
  variant?: 'contained' | 'outlined';
  tooltip?: TooltipProps['title'] | null;
  tooltipProps?: Partial<Omit<TooltipProps, 'title'>>;
}

const Checkbox: FC<CheckboxProps> = ({
  variant = 'outlined',
  color = 'primary',
  disabled,
  tooltip,
  tooltipProps,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  const checkbox = (
    <MuiCheckbox
      disabled={disabled}
      icon={
        <SvgIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM19 19V5H5V19H19Z"
            fill={
              disabled
                ? theme.palette.action.disabled
                : theme.palette.secondary.main
            }
          />
        </SvgIcon>
      }
      {...(variant === 'outlined' && {
        checkedIcon: (
          <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM16.58 7.58L17.99 9L9.99 17L5.99 13.01L7.41 11.6L9.99 14.17L16.58 7.58Z"
              fill={
                disabled
                  ? theme.palette.action.disabled
                  : theme.palette.primary.main
              }
            />
          </SvgIcon>
        ),
      })}
      color={color}
      sx={combineSX(
        {
          p: 0,
          height: 18,
          width: 18,
        },
        sx,
      )}
      {...rest}
    />
  );

  return tooltip && !disabled ? (
    <Tooltip title={tooltip} disableInteractive {...tooltipProps}>
      {checkbox}
    </Tooltip>
  ) : (
    <>{checkbox}</>
  );
};

export default Checkbox;
