import React, { HtmlHTMLAttributes } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Column } from '../Table';
import Checkbox from 'components/Checkbox';
import { Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Typography from 'components/Typography';

interface ColumnCardProps<T> extends HtmlHTMLAttributes<HTMLElement> {
  identifier: string;
  column: Column<T>;
  index: number;
  onVisibilityChange: (newVisibility: boolean) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export default function ColumnCard<T>({
  identifier,
  column,
  index,
  onVisibilityChange,
}: ColumnCardProps<T>) {
  const { classes } = useStyles();

  return (
    <Draggable draggableId={identifier} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.root}
        >
          <div className={classes.controls}>
            <DragHandleIcon sx={theme => ({})} />
            <Checkbox
              checked={column.visible === undefined || column.visible}
              onChange={(e, checked) => onVisibilityChange(checked)}
              variant="contained"
            />
          </div>
          <Typography sx={{ ml: 1, color: 'text.secondary' }}>
            {column.header}
          </Typography>
        </div>
      )}
    </Draggable>
  );
}
