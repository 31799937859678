import React, { HtmlHTMLAttributes } from 'react';
import {
  Theme,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableProps, Column, Columns } from '../Table';
import TableHeadCell from './TableHeadCell';

interface TableHeadProps<T>
  extends Omit<HtmlHTMLAttributes<HTMLElement>, 'onResize'> {
  columns: TableProps<T>['columns'];
  isExpandable: boolean;
  isMultiSelected?: boolean;
  onMultiSelect?: () => void;
  onSort?: TableProps<T>['onSort'];
  onResize?: TableProps<T>['onResize'];
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {},
  sortIcon: {
    color: '#604DFF!important',
  },
}));

export default function TableHead<T>({
  columns,
  isExpandable,
  isMultiSelected,
  onMultiSelect,
  onSort,
  onResize,
}: TableHeadProps<T>) {
  const { classes } = useStyles();

  const handleSort =
    (sortableColumnIdentifier: string, sortableColumn: Column<T>) =>
    (e: React.MouseEvent) => {
      const isMultiSort = e.shiftKey;
      const columnsEntries = Object.entries(columns);
      const newSort = (() => {
        if (!sortableColumn.sort)
          return {
            dir: 'asc',
            pos:
              Math.max(...columnsEntries.map(([, el]) => el.sort?.pos || 0)) +
              1,
          };
        if (sortableColumn.sort.dir === 'asc')
          return { ...sortableColumn.sort, dir: 'desc' };
        return undefined;
      })();
      const newColumns = isMultiSort
        ? ({
            ...columns,
            [sortableColumnIdentifier]: {
              ...sortableColumn,
              sort: newSort,
            },
          } as Columns<T>)
        : columnsEntries.reduce(
            (acc, [identifier, column]: [keyof Columns<T>, Column<T>]) => {
              acc[identifier] = {
                ...column,
                sort:
                  sortableColumnIdentifier === identifier ? newSort : undefined,
              } as Column<T>;
              return acc;
            },
            {} as Columns<T>,
          );
      onSort?.(newColumns);
    };

  const handleResize = (
    resizableColumnIdentifier: string,
    newWidth: number,
  ) => {
    onResize?.(
      {
        ...columns,
        [resizableColumnIdentifier]: {
          ...columns[resizableColumnIdentifier],
          width: newWidth,
        } as Column<T>,
      },
      resizableColumnIdentifier,
    );
  };

  return (
    <MuiTableHead className={classes.root}>
      <MuiTableRow className={classes.row}>
        {isExpandable && <TableHeadCell sortable={false} />}
        {onMultiSelect && (
          <TableHeadCell sortable={false}>
            <IconButton size="small" onClick={onMultiSelect}>
              {isMultiSelected ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM17 11H7V13H17V11Z"
                    fill="#604DFF"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM19 19V5H5V19H19Z"
                    fill="#0000008a"
                  />
                </svg>
              )}
            </IconButton>
          </TableHeadCell>
        )}
        {Object.entries(columns)
          .filter(([, column]) => column.visible !== false)
          .sort(([, { pos: pos1 }], [, { pos: pos2 }]) => {
            return (pos1 as number) - (pos2 as number);
          })
          .map(([identifier, column]) => {
            return (
              <TableHeadCell
                className={column.headerClassName}
                width={column.width}
                sx={column.headerSX}
                key={identifier}
                resizable={onResize && (column.resizable ?? true)}
                identifier={identifier}
                onResize={newWidth => handleResize(identifier, newWidth)}
                sortable={column.sortable}
                sortDirection={column.sort?.dir}
                onSort={handleSort(identifier, column)}
                actionsColumn={column.actionsColumn}
              >
                {column.header}
              </TableHeadCell>
            );
          })}
      </MuiTableRow>
    </MuiTableHead>
  );
}
