import React, { FC, CSSProperties, useRef, useEffect } from 'react';
import { Property } from 'csstype';
import {
  CarouselProvider as PRCCarouselProvider,
  CarouselProviderProps,
} from 'pure-react-carousel';
import { useResizeDetector } from 'react-resize-detector';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ justifySlider: Property.JustifyContent }>()(
  (theme, { justifySlider }) => ({
    outerContainer: {
      display: 'flex',
      justifyContent: justifySlider,
      width: '100%',
      height: '100%',
    },
  }),
);

interface SlideEndDimension {
  slideWidth: number;
  slideHeight: number;
}

interface SlideNaturalDimension {
  naturalSlideWidth: number;
  naturalSlideHeight: number;
}

interface ICarouselProviderProps
  extends Omit<
    CarouselProviderProps,
    'naturalSlideWidth' | 'naturalSlideHeight'
  > {
  style?: CSSProperties;
  classes?: {
    outerContainer?: string;
    innerContainer?: string;
  };
  styles?: {
    outerContainer?: CSSProperties;
    innerContainer?: CSSProperties;
  };
  fitSlides?: boolean;
  justifySlider?: Property.JustifyContent;
  naturalSlideWidth?: number;
  naturalSlideHeight?: number;
  slideWidth?: number;
  slideHeight?: number;
}

const CarouselProvider: FC<
  ICarouselProviderProps & (SlideEndDimension | SlideNaturalDimension)
> = ({
  children,
  naturalSlideWidth = 0,
  naturalSlideHeight = 0,
  slideWidth = 0,
  slideHeight = 0,
  visibleSlides,
  totalSlides,
  styles = {},
  style = {},
  className,
  classes = {},
  fitSlides = false,
  justifySlider = 'normal',
  ...rest
}) => {
  const provider = useRef<any>();
  useEffect(() => {
    if (provider.current) {
      // @ts-ignore
      provider.current.carouselStore.setStoreState({ currentSlide: 0 });
    }
  }, [visibleSlides, totalSlides]);

  const { classes: innerClasses, cx } = useStyles({ justifySlider });
  const resizeDecoratorParams = useResizeDetector();
  const { height = 0, ref } = resizeDecoratorParams;
  let { width = 0 } = resizeDecoratorParams;
  const slidePossibleHeight = slideHeight || height;

  const oneSlideWidth = naturalSlideWidth
    ? (slidePossibleHeight * naturalSlideWidth) / naturalSlideHeight
    : slideWidth;

  let slidesToShow = visibleSlides;
  if (visibleSlides === undefined) {
    // eslint-disable-next-line
    const slidesAmountFitContainer = Math.floor(width / oneSlideWidth) | 0;
    slidesToShow = fitSlides
      ? Math.min(slidesAmountFitContainer, totalSlides)
      : slidesAmountFitContainer;
  }
  width = oneSlideWidth * (slidesToShow || 1);
  const maxWidth =
    (visibleSlides || totalSlides) * (slideWidth || oneSlideWidth);
  const maxWidthObj: any = { maxWidth: maxWidth || width };

  return (
    <div
      // @ts-ignore
      ref={ref}
      style={Object.assign(maxWidthObj, style, styles.outerContainer)}
      className={cx(innerClasses.outerContainer, classes.outerContainer)}
    >
      <div
        style={{
          maxWidth: Math.min(width, maxWidth),
          width: '100%',
          ...styles.innerContainer,
        }}
        className={classes.innerContainer}
      >
        <PRCCarouselProvider
          {...rest}
          totalSlides={totalSlides}
          visibleSlides={Math.max(Math.min(slidesToShow || 1, totalSlides), 1)}
          naturalSlideHeight={slidePossibleHeight}
          naturalSlideWidth={oneSlideWidth}
          //@ts-ignore
          ref={provider}
        >
          {children}
        </PRCCarouselProvider>
      </div>
    </div>
  );
};

export default CarouselProvider;
