import React, { FC } from 'react';
import { Dialog, DialogProps, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IconButton from 'components/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

enum Buttons {
  Esc = 27,
  LeftArrow = 37,
  RightArrow = 39,
}

interface CarouselDialogProps extends Omit<DialogProps, 'open'> {
  totalSlides?: number;
  currentSlide?: number;
  onSlideChange?: (newSlide: number) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

const CarouselDialog: FC<CarouselDialogProps> = ({
  totalSlides,
  currentSlide,
  onSlideChange,
  children,
  ...restProps
}) => {
  const { classes, cx } = useStyles();

  const handleSlideChange = (direction: 'prev' | 'next') => {
    if (!totalSlides || !currentSlide || !onSlideChange) return;

    if (direction === 'next' && currentSlide < totalSlides)
      onSlideChange(currentSlide + 1);
    if (direction === 'prev' && currentSlide > 1)
      onSlideChange(currentSlide - 1);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const key: number = e.keyCode || e.which;
    if (key === Buttons.Esc) {
      restProps.onClose?.(e, 'escapeKeyDown');
    }

    if ([Buttons.RightArrow, Buttons.LeftArrow].includes(key)) {
      handleSlideChange(
        (
          {
            [Buttons.LeftArrow.toString()]: 'prev',
            [Buttons.RightArrow.toString()]: 'next',
          } as const
        )[key],
      );
    }
  };

  return (
    <Dialog
      open
      fullWidth
      scroll="body"
      onKeyDown={onKeyDown}
      {...restProps}
      className={cx(classes.root, restProps.className)}
      PaperProps={{
        ...restProps.PaperProps,
        style: {
          ...restProps.PaperProps?.style,
          boxShadow: 'none',
          overflow: 'visible',
          background: 'transparent',
          position: 'unset',
        },
      }}
    >
      {onSlideChange && (
        <IconButton
          color="primary"
          onClick={() => handleSlideChange('prev')}
          sx={theme => ({
            position: 'absolute',
            left: 80,
            top: '50%',
            height: 120,
            width: 120,
            zIndex: 1,
            ...(currentSlide === 1 && {
              color: theme.palette.action.disabled,
            }),
          })}
        >
          <ArrowBackIosIcon
            sx={{
              height: 120,
              width: 120,
              marginLeft: '35px',
            }}
          />
        </IconButton>
      )}

      {children}
      {onSlideChange && (
        <IconButton
          color="primary"
          onClick={() => handleSlideChange('next')}
          sx={theme => ({
            position: 'absolute',
            right: 80,
            top: '50%',
            height: 120,
            width: 120,
            zIndex: 1,
            ...(currentSlide === totalSlides && {
              color: theme.palette.action.disabled,
            }),
          })}
        >
          <ArrowForwardIosIcon
            sx={{
              height: 120,
              width: 120,
              marginLeft: '35px',
            }}
          />
        </IconButton>
      )}
    </Dialog>
  );
};

export default CarouselDialog;
