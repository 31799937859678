import React, { memo, useCallback, useMemo } from 'react';
import Autocomplete, { AutocompleteProps } from '../Autocomplete';
import { useField } from 'formik';
import useTranslation from 'hooks/useTranslation';
import { AutocompleteChangeReason, AutocompleteValue } from '@mui/material';

export interface FormAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'value'
  > {
  name: string;
}

const MemoAutocomplete = memo(props => {
  return <Autocomplete {...props} />;
}) as typeof Autocomplete;

export default function FormAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  onChange,
  onBlur,
  InputProps: outerInputProps,
  ...rest
}: FormAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<
    any | null | undefined
  >(name);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
    ) => {
      setValue(value);
      onChange?.(event, value, reason);
    },
    [setValue, onChange],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      setTouched(true);
      onBlur?.(e);
    },
    [setTouched],
  );

  const showError = !!(error && touched);
  const helperText =
    error &&
    touched &&
    (typeof error === 'string'
      ? error
      : //@ts-ignore
        t(`yup:${error.key}`, error.values));
  const InputProps = useMemo(
    () => ({
      ...outerInputProps,
      name: name,
      onBlur: () => setTouched(true),
      error: showError,
      helperText,
    }),
    [outerInputProps, name, setTouched, showError, helperText],
  );

  return (
    <MemoAutocomplete
      value={value ?? null}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={InputProps}
      {...rest}
    />
  );
}
