import React, { FC, useState, ReactNode, useMemo } from 'react';
import {
  Popover,
  MenuItem,
  MenuItemProps as MuiMenuItemProps,
  Theme,
  PopoverOrigin,
  TooltipProps,
  Tooltip,
  BoxProps,
  Box,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from 'components/IconButton';
import { combineSX } from '../..';

interface MenuItemPropsPart {
  content: ReactNode;
  action?: (callback: () => void) => void;
  disabled?: boolean;
  tooltip?: TooltipProps['title'] | null;
}
type MenuItemProps = MenuItemPropsPart &
  Omit<MuiMenuItemProps, keyof MenuItemPropsPart>;

interface ThreeDotsMenuProps extends BoxProps {
  options: MenuItemProps[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'inline-flex',
  },
  menuItem: {
    maxWidth: '200px',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  itemWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}));

const ThreeDotsMenu: FC<ThreeDotsMenuProps> = ({
  className,
  options,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  disabled,
  ...rest
}) => {
  const { classes: innerClasses, cx } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const listItems = useMemo(
    () =>
      options.map(
        (
          {
            content,
            action,
            onClick,
            disabled,
            tooltip,
            className: menuItemClassName,
            ...restMenuItemProps
          },
          index,
        ) => {
          const handleAction = (event: any) => {
            event.stopPropagation();
            if (action) {
              action(() => setAnchorEl(null));
            }
          };

          const menuItem = (
            <MenuItem
              className={cx(menuItemClassName, innerClasses.menuItem)}
              key={index}
              disabled={disabled}
              {...(!disabled && {
                onClick: onClick ?? handleAction,
              })}
              {...restMenuItemProps}
              sx={combineSX(
                {
                  '&.Mui-disabled': {
                    pointerEvents: 'all',
                  },
                },
                restMenuItemProps.sx,
              )}
            >
              <div className={innerClasses.itemWrapper}>{content}</div>
            </MenuItem>
          );

          return tooltip ? (
            <Tooltip title={tooltip} disableInteractive>
              {menuItem}
            </Tooltip>
          ) : (
            menuItem
          );
        },
      ),
    [options],
  );

  return (
    <Box className={cx(className, innerClasses.root)} {...rest}>
      <IconButton size="small" onClick={handleClick} disabled={disabled}>
        <MoreVert />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={open}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handleClose}
      >
        {listItems}
      </Popover>
    </Box>
  );
};

export default ThreeDotsMenu;
