import React, { FC, ElementType, useCallback } from 'react';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  SvgIconProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DialogTitle, { DialogTitleProps } from './components/DialogTitle';
import DialogContent from './components/DialogContent';
import DialogActions from './components/DialogActions';
import { CancelButtonProps as CancelButtonPropsType } from 'components/Button/CancelButton';
import { ConfirmButtonProps as ConfirmButtonPropsType } from 'components/Button/ConfirmButton';
import Title, { TitleProps } from '../Title/Title';

export interface DialogProps extends Omit<MuiDialogProps, 'open'> {
  open?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  mainTitle?: string;
  subTitle?: string;
  TitleIcon?: ElementType<SvgIconProps>;
  CancelButtonProps?: Partial<CancelButtonPropsType>;
  ConfirmButtonProps?: Partial<ConfirmButtonPropsType>;
  DialogTitleProps?: Partial<DialogTitleProps> & {
    classes?: TitleProps['classes'];
  };
  tabs?: string[];
  selectedTab?: number;
  onChangeTab?: (newTab: number) => void;
  getTabDisabled?: (tabIndex: number) => boolean;
}

const useStyles = makeStyles()({
  title: {
    maxWidth: 'calc(100% - 24px)',
  },
});

const Dialog: FC<DialogProps> = ({
  children,
  onClose,
  onConfirm,
  mainTitle,
  subTitle,
  TitleIcon,
  open = true,
  CancelButtonProps,
  ConfirmButtonProps,
  DialogTitleProps,
  tabs,
  selectedTab,
  onChangeTab,
  getTabDisabled,
  ...rest
}) => {
  const { classes } = useStyles();

  const isCloseDisabled = ConfirmButtonProps?.loading;

  const handleClose = useCallback(() => {
    if (!isCloseDisabled) onClose();
  }, [onClose, isCloseDisabled]);

  return (
    <MuiDialog open={open} onClose={handleClose} {...rest}>
      {(mainTitle || subTitle || TitleIcon) && (
        <DialogTitle
          onClose={handleClose}
          isCloseDisabled={isCloseDisabled}
          classes={{
            root: DialogTitleProps?.classes?.root,
            closeButton: DialogTitleProps?.classes?.closeButton,
          }}
          tabs={tabs}
          selectedTab={selectedTab}
          onChangeTab={onChangeTab}
          getTabDisabled={getTabDisabled}
        >
          <Title
            className={classes.title}
            subTitle={subTitle}
            Icon={TitleIcon}
            classes={{
              title: DialogTitleProps?.classes?.title,
              subTitle: DialogTitleProps?.classes?.subTitle,
            }}
          >
            {mainTitle}
          </Title>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {onConfirm && (
        <DialogActions
          onConfirm={onConfirm}
          onClose={handleClose}
          CancelButtonProps={{
            ...CancelButtonProps,
            ...(isCloseDisabled && { disabled: isCloseDisabled }),
          }}
          ConfirmButtonProps={ConfirmButtonProps}
        />
      )}
    </MuiDialog>
  );
};

export default Dialog;
