import React, { FC, CSSProperties } from 'react';
import { Box, Switch as MuiSwitch, Theme } from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
import Typography from 'components/Typography';

export const StyledSwitch = withStyles(
  MuiSwitch,
  (theme: Theme, _params, classes) => {
    console.log(_params);
    return {
      root: {
        width: 24,
        height: 12,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        overflow: 'visible',
      },
      switchBase: {
        padding: 2,
        color: '#757575',
      },
      thumb: {
        width: 8,
        height: 8,
        boxShadow: 'none',
        color: '#757575',
        ...(_params.checked && {
          color: theme.palette.primary.main,
        }),
      },
      track: {
        border: `1px solid #757575`,
        borderRadius: 8,
        opacity: 1,
        backgroundColor: 'white',
        ...(_params.checked && {
          borderColor: theme.palette.primary.main,
        }),
      },
      checked: {
        transform: 'translateX(11px)!important',
        color: 'white',
      },
    };
  },
);

export interface SwitchProps {
  label: string;
  labelProps?: any;
  onToggle: (e: any) => void;
  checked?: boolean;
  id?: string;
  className?: string;
  classes?: {
    title?: string;
    switch?: string;
    label?: string;
  };
  style?: CSSProperties;
}

interface SwitchStyleProps {
  checked?: boolean;
}

const useStyles = makeStyles<SwitchStyleProps>()((theme, { checked }) => ({
  label: {
    marginLeft: 10,
    color: checked ? theme.palette.primary.main : theme.palette.text.secondary,
  },
}));

const Switch: FC<SwitchProps> = ({
  label,
  labelProps,
  onToggle,
  checked,
  className,
  classes,
  style,
}) => {
  const { classes: innerClasses, cx } = useStyles({ checked });
  return (
    <Box display="flex" className={className} alignItems="center" style={style}>
      <StyledSwitch checked={checked} onChange={onToggle} />
      <Typography
        className={cx(innerClasses.label, classes?.label)}
        {...labelProps}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default Switch;
