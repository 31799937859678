import React, { FC } from 'react';
import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material';
import { combineSX } from 'helpers';

export interface RadioProps extends Omit<MuiRadioProps, 'size'> {}

const Radio: FC<RadioProps> = ({ sx, ...rest }) => {
  return (
    <MuiRadio
      size="small"
      sx={combineSX(
        {
          p: 0,
          width: 18,
          height: 18,
        },
        sx,
      )}
      {...rest}
    />
  );
};

export default Radio;
