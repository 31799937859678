import React, { FC, forwardRef } from 'react';
import {
  ButtonNext as PRCButtonNext,
  ButtonNextProps,
  WithStore,
} from 'pure-react-carousel';

interface StateData {
  currentSlide: number;
  totalSlides: number;
  visibleSlides: number;
}

interface IButtonNext extends ButtonNextProps {
  hideOnNoSteps?: boolean;
}

const ButtonNext: FC<IButtonNext & StateData> = forwardRef(
  (props, ref: any) => {
    const {
      hideOnNoSteps = false,
      currentSlide,
      totalSlides,
      visibleSlides,
      ...rest
    } = props;

    if (hideOnNoSteps && currentSlide + visibleSlides >= totalSlides) {
      return null;
    }

    return <PRCButtonNext ref={ref} {...rest} />;
  },
);

// @ts-ignore
export default WithStore<IButtonNext, StateData>(ButtonNext, state => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides,
  visibleSlides: state.visibleSlides,
}));
