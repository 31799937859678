import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import { InputAdornment, Theme } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import TextField, { TextFieldProps } from 'components/TextField';
import { SearchIcon } from 'components/Icons';

export interface SearchProps extends Omit<TextFieldProps, 'onChange'> {
  onChange(value: string): void;
  waitTime?: number;
}

const Search = ({
  onChange,
  waitTime = 500,
  value = '',
  placeholder,
  ...rest
}: SearchProps): ReactElement => {
  const [searchValue, setSearchValue] = useState(value);
  const { t } = useTranslation();

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const debouncedSearch = useDebounceCallback(onChange, waitTime);

  const handleChange = useCallback(
    (searchString: string) => {
      setSearchValue(searchString);
      debouncedSearch(searchString);
    },
    [debouncedSearch],
  );

  return (
    <TextField
      {...rest}
      placeholder={placeholder || `${t('Search')}`}
      clearable
      value={searchValue}
      onChange={e => handleChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              sx={theme => ({
                color: theme.palette.text.secondary,
              })}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
