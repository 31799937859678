import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  progress: {
    margin: 18,
  },
});

interface IProgressProps {
  color?: CircularProgressProps['color'];
  [key: string]: any;
}

const Progress: React.FC<IProgressProps> = ({ color, ...other }) => {
  const { classes } = useStyles();
  return (
    <CircularProgress className={classes.progress} color={color} {...other} />
  );
};

export default Progress;
