import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu, { type MenuProps } from '@mui/material/Menu';
import { MRT_ShowHideColumnsMenuItems } from './MRT_ShowHideColumnsMenuItems';
import {
  type MRT_Column,
  type MRT_RowData,
  type MRT_TableInstance,
  getDefaultColumnOrderIds,
} from 'material-react-table';
import Autocomplete from 'components/Autocomplete';
import useTranslation from 'hooks/useTranslation';
import { useDebounceCallback } from '@react-hook/debounce';

interface MRT_ShowHideColumnsMenuProps<TData extends MRT_RowData>
  extends Partial<MenuProps> {
  anchorEl: HTMLElement | null;
  isSubMenu?: boolean;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  table: MRT_TableInstance<TData>;
}

export const MRT_ShowHideColumnsMenu = <TData extends MRT_RowData>({
  anchorEl,
  setAnchorEl,
  table,
  ...rest
}: MRT_ShowHideColumnsMenuProps<TData>) => {
  //cc
  const { t } = useTranslation();
  const {
    getAllColumns,
    getAllLeafColumns,
    getCenterLeafColumns,
    getIsAllColumnsVisible,
    getIsSomeColumnsPinned,
    getIsSomeColumnsVisible,
    getLeftLeafColumns,
    getRightLeafColumns,
    getState,
    options: {
      enableColumnOrdering,
      enableColumnPinning,
      enableHiding,
      localization,
      mrtTheme: { menuBackgroundColor },
    },
  } = table;
  const { columnOrder, columnPinning, density, columnVisibility } = getState();

  const handleToggleAllColumns = (value?: boolean) => {
    getAllLeafColumns()
      .filter(col => col.columnDef.enableHiding !== false)
      .forEach(col => col.toggleVisibility(value));
  };

  const allColumns = useMemo(() => {
    const columns = getAllColumns();
    if (
      columnOrder.length > 0 &&
      !columns.some(col => col.columnDef.columnDefType === 'group')
    ) {
      return [
        ...getLeftLeafColumns(),
        ...Array.from(new Set(columnOrder)).map(colId =>
          getCenterLeafColumns().find(col => col?.id === colId),
        ),
        ...getRightLeafColumns(),
      ].filter(Boolean);
    }
    return columns;
  }, [
    columnOrder,
    columnPinning,
    getAllColumns(),
    getCenterLeafColumns(),
    getLeftLeafColumns(),
    getRightLeafColumns(),
  ]) as MRT_Column<TData>[];

  const isNestedColumns = allColumns.some(
    col => col.columnDef.columnDefType === 'group',
  );

  const [hoveredColumn, setHoveredColumn] = useState<MRT_Column<TData> | null>(
    null,
  );

  //cc
  const [inputValue, setInputValue] = React.useState('');
  const [visibleColumns, hiddenColumns] = useMemo(
    () => [
      allColumns.filter(el => columnVisibility[el.id] !== false),
      allColumns.filter(el => columnVisibility[el.id] === false),
    ],
    [allColumns, columnVisibility],
  );
  const onColumnsReset = useDebounceCallback(() =>
    table.setColumnOrder(getDefaultColumnOrderIds(table.options as any, true)),
  );

  return (
    <Menu
      MenuListProps={{
        dense: density === 'compact',
        sx: {
          backgroundColor: menuBackgroundColor,
        },
      }}
      anchorEl={anchorEl}
      disableScrollLock
      onClose={() => setAnchorEl(null)}
      open={!!anchorEl}
      {...rest}
    >
      {
        //cc
      }
      <Box
        sx={{
          p: '0.5rem',
          pt: 0,
        }}
      >
        {
          //cc
        }
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {enableHiding && (
            <Button
              disabled={!getIsSomeColumnsVisible()}
              onClick={() => handleToggleAllColumns(false)}
            >
              {localization.hideAll}
            </Button>
          )}
          {enableColumnOrdering && (
            <Button onClick={onColumnsReset}>{localization.resetOrder}</Button>
          )}
          {enableColumnPinning && (
            <Button
              disabled={!getIsSomeColumnsPinned()}
              onClick={() => table.resetColumnPinning(true)}
            >
              {localization.unpinAll}
            </Button>
          )}
          {enableHiding && (
            <Button
              disabled={getIsAllColumnsVisible()}
              onClick={() => handleToggleAllColumns(true)}
            >
              {localization.showAll}
            </Button>
          )}
        </Box>
        {
          //cc
          enableHiding && (
            <Box onKeyDown={e => e.stopPropagation()}>
              <Autocomplete
                value={null}
                options={hiddenColumns}
                getOptionLabel={option => option.columnDef.header}
                onChange={(e, column) => {
                  column && column.toggleVisibility();
                  setInputValue('');
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                InputProps={{
                  placeholder: t('Add Column'),
                }}
              />
            </Box>
          )
        }
      </Box>
      <Divider />
      {visibleColumns
        //cc
        .filter(column => column.columnDef.columnDefType !== 'display')
        .map((column, index) => (
          <MRT_ShowHideColumnsMenuItems
            allColumns={allColumns}
            column={column}
            hoveredColumn={hoveredColumn}
            isNestedColumns={isNestedColumns}
            key={`${index}-${column.id}`}
            setHoveredColumn={setHoveredColumn}
            table={table}
          />
        ))}
    </Menu>
  );
};
