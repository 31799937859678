import React, { FC } from 'react';
import SideBar, { SideBarProps } from './SideBar';
import Content, { ContentProps } from './Content';

export interface LayoutProps extends React.PropsWithChildren {
  contentProps?: Partial<ContentProps>;
  sidebarProps?: Partial<SideBarProps>;
}

const Layout: FC<LayoutProps> = ({ children, contentProps, sidebarProps }) => {
  return (
    <>
      <SideBar {...sidebarProps} />
      <Content {...contentProps}>{children}</Content>
    </>
  );
};

export default Layout;
